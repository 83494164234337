<template>
    <div class="add-to-calendar-menu">
        <div class="add-to-calendar-menu-body">
            <div class="add-to-calendar-menu-panels">
                <div class="add-to-calendar-menu-panels-group">
                    <div v-if="currentEventLinks.google" class="add-to-calendar-menu-panel">
                        <a class="add-to-calendar-menu-panel-link" target="_blank" :href="currentEventLinks.google">
                            <i style="margin-right: 10px;" class="fab fa-google"></i> Google Calendar
                        </a>
                    </div>
                    <div v-if="currentEventLinks.ical" class="add-to-calendar-menu-panel">
                        <a class="add-to-calendar-menu-panel-link" target="_blank" :href="currentEventLinks.ical">
                            <i style="margin-right: 10px;" class="fab fa-apple"></i> .ics File
                        </a>
                    </div>
                    <div v-if="currentEventLinks.off365" class="add-to-calendar-menu-panel">
                        <a class="add-to-calendar-menu-panel-link" target="_blank" :href="currentEventLinks.off365">
                            <i style="margin-right: 10px;" class="fab fa-microsoft"></i> Office 365
                        </a>
                    </div>
                    <div v-if="currentEventLinks.yahoo" class="add-to-calendar-menu-panel">
                        <a class="add-to-calendar-menu-panel-link" target="_blank" :href="currentEventLinks.yahoo">
                            <i style="margin-right: 10px;" class="fab fa-yahoo"></i> Yahoo Calendar
                        </a>
                    </div>
                </div>

                <div class="add-to-calendar-menu-panels-group add-to-calendar-menu-panels-group-last">
                    <div @click="hideAddToCalendarMenu" class="add-to-calendar-menu-panel add-to-calendar-menu-panel-cancel">Cancel</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import { HIDE_ADD_TO_CALENDAR_MENU } from "../../../../store/actions/ui";
    import { CURRENT_EVENT_LINKS } from "../../../../store/getters/ui";

    export default {
        name: "AddToCalendarMenu",
        methods: {
            ...mapActions({
                hideAddToCalendarMenu: HIDE_ADD_TO_CALENDAR_MENU
            })
        },
        computed: {
            ...mapGetters({
                currentEventLinks: CURRENT_EVENT_LINKS
            })
        }
    }
</script>

<style lang="scss" scoped>
    a {
        color: inherit !important;
        text-decoration: none !important;

        &:hover {
            color: inherit !important;
            text-decoration: none !important;
        }
    }

    .add-to-calendar-menu {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background: rgba(0, 0, 0, 0.5); // Make sure this color has an opacity of less than 1
        backdrop-filter: blur(20px);
        z-index: 20;

        &-body {
             display: flex;
             align-items: flex-end;
             height: 100%;
             overflow-y: auto;
        }

        &-panels {
             width: 100%;
             text-align: center;
             margin: 0 12px;
             margin-bottom: 10px;

            &-group {
                 border-radius: 6px;
                 overflow: hidden;
                 &-last {
                     margin-top: 8px;
                 }
            }
        }

        &-panel {
             margin: 1px 0;
             background: #d6d6d6;
             cursor: pointer;
            &-link {
                padding: 10px;
                width: 100%;
                display: block;
            }
            &-cancel {
                padding: 10px;
            }
        }
    }

    .container h2 {
        padding-top: 10px;
        font-size: 24px;
        text-align: center;
    }

    .container iframe {
        border: none;
        width: 100%;
    }

    .container {
        overflow-y: auto;
        height: calc(100vh);
    }

    @media only screen and (min-width: 480px) {
        .container {
            height: 695px;
        }
    }
</style>