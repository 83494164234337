<template>
  <div class="container" style="padding-bottom: 0;">
    <p id="name" :class="{fs16: fullName.length > 25}">{{ fullName }}</p>
    <div id="groups-wrapper">
      <div id="groups-wrapper-inner">
        <div class="group" v-for="(value, name) in profile.groups" :style="{ 'background-color': '#' + value}" :key="name">
          <p>{{ name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "ProfileInfo",
        props: [
            'profile'
        ],
        computed: {
            fullName() {
                return this.profile.first_name + ' ' + this.profile.last_name
            }
        }
    }
</script>

<style scoped>

  #name {
    font-size: 22px;
    text-align: center;
    margin-top: 15px
  }

  #groups-wrapper-inner {
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  .group {
    margin: 0 3px;
    display: inline-block;
    padding: 7px 20px;
    border-radius: 30px;
  }

  .group p {
    color: #FFF;
    margin-bottom: 0;
  }

  .fs16 {
    font-size: 16px !important;
  }
</style>