<template>
  <div id="apps">
    <apps-header heading="Menu" use-custom-handler="true" @back-button-click="goTo('Home')"/>
    <div class="container" id="appsContainer">
      <div class="apps-container">
        <div class="apps-item with-forward-arrow" @click="onDirectoryClick" v-if="menuName('APP_DIRECTORY')">
          <!-- <img :src="`${this.$basePath}images/apps/magnifier.svg`" alt="Key"> -->
          <i class="apps-icon far fa-folder-open"></i>
          <div class="item-name">Directory</div>
        </div>
        <div class="apps-item with-forward-arrow" v-if="menuName('APP_MY_COURSES')">
          <!-- <img :src="`${this.$basePath}images/apps/gift.svg`" alt="Key"> -->
          <i class="apps-icon far fa-address-card"></i>
          <div class="item-name">My courses</div>
        </div>
        <div class="apps-item with-forward-arrow" v-if="menuName('APP_CALENDAR')" @click="goTo('Calendar')">
          <!-- <img :src="`${this.$basePath}images/apps/calendar.svg`" alt="Key"> -->
          <i class="apps-icon far fa-calendar-alt"></i>
          <div class="item-name">Calendar</div>
        </div>
        <div class="apps-item with-forward-arrow" v-if="menuName('APP_NEWS')" @click="goTo('News')">

          <!-- <img :src="`${this.$basePath}images/apps/news.svg`" alt="Key"> -->
          <i class="apps-icon far fa-newspaper"></i>
          <div class="item-name">News</div>
        </div>
        <div class="apps-item with-forward-arrow" v-if="menuName('APP_ANNOUNCEMENTS')" @click="goTo('Announcements')">
          <i class="apps-icon fas fa-ad"></i>
          <div class="item-name">Announcements</div>
        </div>
        <div :class="`apps-item with-forward-arrow ${universityServicesMenu ? 'with-forward-arrow-down' : ''}`" v-if="menuName('APP_UNIVER_SERVICES')" @click="toggleUniversityServicesMenu">
          <!-- <img :src="`${this.$basePath}images/apps/wallet.svg`" alt="Key"> -->
          <i class="apps-icon far fa-credit-card"></i>
          <div class="item-name">University services</div>
        </div>
        <!-- -->
        <div class="apps-item with-forward-arrow" v-if="menuName('APP_TICKET_DISTR')">
          <i class="apps-icon fas fa-ticket-alt"></i>
          <div class="item-name">Ticket distribution</div>
        </div>
        <div class="apps-item with-forward-arrow" v-if="menuName('APP_DIGITAL_MAG')">
          <i class="apps-icon fas fa-book-open"></i>
          <div class="item-name">Digital magazine</div>
        </div>
        <div class="apps-item with-forward-arrow" v-if="menuName('APP_ALUMNI_DEALS')">
          <i class="apps-icon fas fa-award"></i>
          <div class="item-name">Alumni deals</div>
        </div>
        <!-- -->
        <div class="apps-item with-forward-arrow" v-if="menuName('APP_HUMAN_RESOURCES')">
          <i class="apps-icon fas fa-male"></i>
          <div class="item-name">Human resources</div>
        </div>

        <transition
                name="slide"
        >
          <div
                  class="apps-item-submenu"
                  @click.stop=""
                  :style="`max-height: ${universityServicesMenu ? '400px' : '0'};`"
          >
            <div
                    v-for="(item, i) in universityServicesMenuItems"
                    :key="i"
                    class="apps-item apps-item-submenu-item"
            >
              {{ item.value }}
            </div>
          </div>
        </transition>
        <div class="apps-item with-forward-arrow" v-if="menuName('APP_CYBERSECURE')">
          <i class="fas fa-user-lock apps-icon"></i>
          <div class="item-name">Cybersecure</div>
        </div>

        <div class="apps-item with-forward-arrow" v-if="menuName('APP_UNIVER_LOTTERY')">
          <i class="fas fa-ticket-alt apps-icon"></i>
          <div class="item-name">University housing lottery</div>
        </div>

        <div class="apps-item with-forward-arrow" v-if="menuName('APP_COMPLIANCE_TRAINING')">
          <i class="apps-icon fas fa-check-circle"></i>
          <div class="item-name">Compliance training</div>
        </div>

        <div class="apps-item with-forward-arrow">
          <i class="fas fa-clock apps-icon"></i>
          <div class="item-name">Timesheet</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import AppsHeader from "@/components/pages/Apps/AppsHeader";
    import { mapActions } from 'vuex';
    import { USER_REQUEST } from "@/store/actions/user";
    import { AppMenuNames } from "@/store/const/applications";
    import router from "@/router";

    export default {
        name: "Apps",
        props: ['profile'],
        components: {
            AppsHeader
        },
        data () {
          return {
            applications: [],
            universityServicesMenu: false,
            universityServicesMenuItems: [
              {
                value: 'Technology',
                link: ''
              },
              {
                value: 'Housing services',
                link: ''
              },
              {
                value: 'Mental health services',
                link: ''
              },
              {
                value: 'Registrar services',
                link: ''
              },
              {
                value: 'Academic services',
                link: ''
              }
            ]
          }
        },
        methods: {
          onDirectoryClick() {
            if (!this.profile.directory_url) return;
            router.push({name: 'Directory'})
          },
          goTo(name) {
            router.push({name})
          },
          showUniversityServicesMenu(){
            this.universityServicesMenu = true;
          },
          closeUniversityServicesMenu(){
            this.universityServicesMenu = false;
          },
          toggleUniversityServicesMenu(){
            this.universityServicesMenu = !this.universityServicesMenu;
          },
          ...mapActions({
            fetchUserProfile: USER_REQUEST
          }),
          adjustContainerHeight() {
            const app = document.getElementById('app');
            const directoryContainer = document.getElementById('appsContainer');
            if (app && directoryContainer) {
              directoryContainer.style.height = app.clientHeight - 117 + 'px';
            }
          },
          menuName(name) {
            return this.applications.includes(AppMenuNames[name]);
          }
        },
        beforeRouteEnter(to, from, next){
          next(vm => {
            vm.fetchUserProfile();
          });
        },
        mounted() {
          this.adjustContainerHeight();

          if (this.profile.hasOwnProperty('apps')) {
            this.applications = this.profile.apps || [];
          }
        },
        watch: {
          profile: {
          immediate: true, // the callback will be called immediately after the start of the observation
          handler () {
            this.applications = this.profile.apps || [];
          }
          }
        }
    }
</script>

<style scoped>
  .container {
    overflow-y: auto;
    height: calc(100vh - 117px);
  }

  .apps-item {
    height: 67px;
    margin: 7px 0;
    display: flex;

    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    box-shadow: 0 2px 10px rgba(227, 227, 227, 0.5);
    border-radius: 5px;
    cursor: pointer;
  }

  .apps-item:first-child {
    margin-top: 22px;
  }

  .apps-item:last-child {
    margin-bottom: 30px;
  }

  .apps-item img {
    padding: 0 28px;
  }

  .item-name {
    line-height: 67px;
    font-size: 18px;
    color: #313131;
  }

  .apps-item-submenu {
    position: relative;
    transition: max-height .5s;
    overflow: hidden;
  }

  .apps-item-submenu-item {
    padding-left: 20px;
    display: flex;
    align-items: center;
    margin: 0 !important;
    box-shadow: none;
  }

  .apps-item-submenu > .apps-item-submenu-item:first-child {
    margin-top: 0;
  }

  .apps-item-submenu-item.with-forward-arrow::after {
    top: auto;
  }

  .with-forward-arrow-down::after {
    transition: all .5s ease;
    transform: rotate(45deg);
  }

  .apps-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 79px;
    color: #4897F7;
    font-size: 22px;
  }
</style>
