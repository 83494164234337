<template>
  <div>
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
          <component :is="Component" :profile="profile"/>
      </transition>
    </router-view>
    <app-menu :profile="profile"></app-menu>
  </div>
</template>

<script>
    import Menu from "@/components/parts/Menu";
    import {mapState} from "vuex";

    export default {
        name: "Main",
        components: {
            'app-menu': Menu,
        },
        computed: {
            ...mapState({profile: state => state.user.profile}),
        },
    }
</script>

<style>
  .container {
    padding: 0 12.5px 57px;
  }
</style>
