import {
    NOTIFICATIONS_ERROR,
    NOTIFICATIONS_REQUEST,
    NOTIFICATIONS_SUCCESS,
    NOTIFICATIONS_INTERVAL,
    NOTIFICATIONS_PING,
    NOTIFICATIONS_SEND_READ,
    NOTIFICATIONS_INTERVAL_CLEAR
} from '../actions/notifications'
import {SET_NOTIFICATIONS_INTERVAL} from "../mutations/notifications";
import client from '@/client'

const state = {
    notifications: null,
    types: null,
    status: '',
    notificationsInterval: null,
    alert: false
};

const actions = {
    [NOTIFICATIONS_SEND_READ]: (context => {
        const readNotificationsIds = localStorage.getItem('read_notifications_ids')

        if (readNotificationsIds) {
            const readIdsArray = readNotificationsIds.split(',')

            client.post('/notifications', {
                read_notifications: readIdsArray
            }).then(response => {
                if (response.data.status === 'success') {
                    localStorage.removeItem('read_notifications_ids');
                }
                context.dispatch(NOTIFICATIONS_PING);
            })
        } else {
            context.dispatch(NOTIFICATIONS_PING);
        }
    }),
    [NOTIFICATIONS_PING]: (context => {
        console.log('NOTIFICATIONS_PING started');

        context.commit(NOTIFICATIONS_REQUEST);
        return client.get('/notifications')
            .then(resp => {
                //console.log('Notifications success');
                //console.log(resp.data);
                context.commit(NOTIFICATIONS_SUCCESS, resp.data);
            })
            .catch(err => {
                console.log('Notifications error');
                console.log(err);
                context.commit(NOTIFICATIONS_ERROR);
            })
    }),
    [NOTIFICATIONS_INTERVAL]: (context => {
        const interval = setInterval(() => {
            context.dispatch(NOTIFICATIONS_SEND_READ);
        }, 7000);

        context.commit(SET_NOTIFICATIONS_INTERVAL, {
            interval
        });
    }),
    [NOTIFICATIONS_INTERVAL_CLEAR]: (context => {
        context.commit(SET_NOTIFICATIONS_INTERVAL, false)
    }),
    [NOTIFICATIONS_REQUEST]: ({commit, dispatch}) => {

        // try to send read notification to server
        setInterval(function () {
            const readNotificationsIds = localStorage.getItem('read_notifications_ids')

            if (readNotificationsIds) {

                const readIdsArray = readNotificationsIds.split(',')

                client.post('/notifications', {
                    read_notifications: readIdsArray
                }).then(response => {
                    if (response.data.status === 'success') {
                        localStorage.removeItem('read_notifications_ids')
                        dispatch(NOTIFICATIONS_REQUEST)
                    }
                })
            }
        }, 5000)

        return new Promise((resolve, reject) => {
            commit(NOTIFICATIONS_REQUEST)
            client.get('/notifications')
                .then(resp => {
                    commit(NOTIFICATIONS_SUCCESS, resp.data)
                    resolve()
                })
                .catch(err => {
                    commit(NOTIFICATIONS_ERROR)
                    reject(err)
                })
        })
    },
}

const mutations = {
    [SET_NOTIFICATIONS_INTERVAL]: (state, payload) => {
        if (state.notificationsInterval) {
            clearInterval(state.notificationsInterval);
        }
        if (payload) state.notificationsInterval = payload.interval;
    },
    [NOTIFICATIONS_REQUEST]: (state) => {
        state.status = 'loading'
    },
    [NOTIFICATIONS_SUCCESS]: (state, data) => {
        state.status = 'success'
        state.notifications = data.notifications
        state.types = data.types
        // update notifications in localstorage
        localStorage.setItem('notifications', JSON.stringify(data))
    },
    [NOTIFICATIONS_ERROR]: (state) => {
        state.status = 'error'
        // get notifications from localstorage
        const jsonNotifications = localStorage.getItem('notifications')
        const notifications = JSON.parse(jsonNotifications)

        state.notifications = notifications.notifications
        state.types = notifications.types
    },
}

export default {
    state,
    actions,
    mutations,
}
