<template>
  <div>
    <div ref="shadow" class="shadow"></div>
    <preloader :show="loadingStatus === 'loading'"></preloader>
    <router-view/>
  </div>
</template>

<script>
    import {USER_REQUEST} from "@/store/actions/user";
    import client from './client';
    import {NOTIFICATIONS_INTERVAL, NOTIFICATIONS_PING} from "./store/actions/notifications";
    import {mapGetters, mapActions} from "vuex";
    import Preloader from "./components/Preloader.vue";
    import router from "@/router";

    export default {
        name: 'app',
        components: {
          Preloader
        },
        methods: {
          ...mapActions({
            requestUser: USER_REQUEST,
            requestNotifications: NOTIFICATIONS_PING
          }),
          initPushNotifications() {
            document.addEventListener('deviceready', () => {
              this.registerPushNotifications();
            }, false);
          },
          registerPushNotifications(){
            let iosSettings = {};
            iosSettings["kOSSettingsKeyAutoPrompt"] = false;
            iosSettings["kOSSettingsKeyInAppLaunchURL"] = true;

            window.plugins.OneSignal
                    .startInit("e745062b-7606-4e52-9744-98c95c8c6494")
                    .handleNotificationReceived(function(jsonData) {
                      // alert("Notification received: \n" + JSON.stringify(jsonData));
                      // console.log('Did I receive a notification: ' + JSON.stringify(jsonData));

                    })
                    .handleNotificationOpened(function(jsonData) {
                      // alert("Notification opened: \n" + JSON.stringify(jsonData));
                      router.push({ name: 'Notifications'});
                      // console.log('didOpenRemoteNotificationCallBack: ' + JSON.stringify(jsonData));
                    })
                    // .inFocusDisplaying(window.plugins.OneSignal.OSInFocusDisplayOption.InAppAlert)
                    .iOSSettings(iosSettings)
                    .endInit();
          }
        },
        computed: {
          ...mapGetters({
            loadingStatus: 'getLoadingStatus'
          })
        },
        created() {
            this.initPushNotifications();

            this.$eventBus.$on('hardShadowOn', () => {
                this.$refs.shadow.classList.remove('opacity07')
                this.$refs.shadow.classList.add('view')
            })

            this.$eventBus.$on('softShadowOn', () => {
                this.$refs.shadow.classList.add('view', 'opacity07')
            })

            this.$eventBus.$on('shadowsOff', () => {
                this.$refs.shadow.classList.remove('view', 'opacity07')
            })

            if (this.$store.getters.isAuthenticated) {
                client.interceptors.request.use(
                    (config) => {
                        let token = this.$store.state.auth.token

                        if (token) {
                            config.headers['Authorization'] = `Bearer ${token}`
                        }

                        return config
                    },

                    (error) => {
                        return Promise.reject(error)
                    }
                );
                this.requestUser()
                  .then(() => {
                    //console.log('user done');
                    //console.log('requesting notifications');
                    this.requestNotifications();
                  })
                  .then(() => {
                    this.$store.dispatch(NOTIFICATIONS_INTERVAL);
                  })
            }
        }
    }

</script>

<style>
  .root-header {
    height: 60px;
    padding-top: 10px;

    position: relative;

    box-shadow: 0 2px 10px rgba(227, 227, 227, 0.5);
  }

  .root-header h1 {
    font-family: avenir_nextmedium, sans-serif;
    font-size: 20px;
    line-height: 41px;
    text-align: center;
    color: #FFFFFF;

    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .back-link {
    cursor: pointer;
    position: absolute;
    left: 20px;
    top: 16px;
  }

  .fade-enter-active {
    transition: opacity .15s ease-in;
  }

  .fade-leave-active {
    transition: all .15s ease-out;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .with-forward-arrow {
    position: relative;
  }

  .with-forward-arrow::after {
    position: absolute;
    display: inline-block;
    right: 22px;
    top: 46%;
    width: 6px;
    height: 6px;
    content: '';

    border: solid #C7C7C7;
    border-width: 0 2px 2px 0;
    padding: 3px;
    vertical-align: middle;
    transform: rotate(-45deg);
  }
</style>
