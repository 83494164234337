<template>
  <div>
    <apps-header heading="Settings" use-custom-handler="true" @back-button-click="goToHome"/>
    <div class="container">
      <div class="settings-container">
        <div class="settings-item with-forward-arrow">Module Management</div>
        <div class="settings-item with-forward-arrow">
          Push Notifications <span class="gray-text">Disabled</span>
        </div>

        <div class="settings-item mt-5">Give Feedback</div>
        <div class="settings-item">Show Info</div>

        <button class="btn btn-outline-danger mt-5 control-btn">
          <i class="fa fa-redo mr-2"/>
          Reset Defaults
        </button>

        <button class="btn btn-outline-success mt-4 control-btn mb-5" @click="logout">
          <i class="fa fa-sign-out-alt mr-2"/>
          Logout
        </button>
      </div>
    </div>
  </div>
</template>

<script>
    import {AUTH_LOGOUT} from "@/store/actions/auth";
    import LogoHeader from "@/components/parts/LogoHeader";
    import AppsHeader from "./Apps/AppsHeader";
    import router from "@/router";

    export default {
        name: "Profile",
        props: ['profile'],
        components: {
          // 'logo-header': LogoHeader,
          'apps-header': AppsHeader
        },
        methods: {
            logout() {
                this.$store.dispatch(AUTH_LOGOUT).then(() => router.push({name: 'Login'}))
            },
            goToHome() {
              router.push({name: 'Home'})
            },
        }
    }
</script>

<style scoped>

  .settings-container {
    overflow-y: auto;
    height: calc(100vh - 127px);
  }

  .settings-item {
    height: 67px;
    margin: 7px 0;
    padding: 20px 40px 20px 30px;

    display: flex;
    justify-content: space-between;

    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    box-shadow: 0 2px 10px rgba(227, 227, 227, 0.5);
    border-radius: 5px;
    cursor: pointer;
  }

  .gray-text {
    color: #939393;
  }

  .control-btn {
    width: 80%;
    height: 50px;
    display: block;
    margin: 50px auto 0;
  }
</style>
