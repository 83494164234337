<template>
  <transition name="fade" mode="out-in">
    <div class="card-wrapper" :key="$route.params.name">
      <div>
        <img id="barcode-image"
             :class="getBarcodeType"
             :src="barcodeContent"
             @click="goToHome"
             alt="Barcode">
      </div>
      <p id="card-data">{{ card?.name || '' }} #: {{ card?.original_id }}</p>
    </div>
  </transition>
</template>

<script>

    import {mapState} from "vuex";
    import router from "@/router";

    export default {
        name: "Card",
        beforeMount() {
            this.card = this.profile.cards.find(card => card.name === this.$route.params.name)
        },
        data() {
            return {
                card: null
            }
        },
        computed: {
            ...mapState({profile: state => state.user.profile}),
            barcodeContent() {
                return 'data:image/png;base64,' + (this.card?.barcode || '')
            },
          getBarcodeType() {
              return this.card?.barcode_type || 'qr_code'
          },
        },
        watch: {
            $route(to) {
                this.card = this.profile.cards.find(card => card.name === to.params.name)
            }
        },
      methods: {
          goToHome() {
            router.push({ path: '/', params: {} })
          },
      },
    }
</script>

<style scoped>
  .card-wrapper {
    /*position: absolute;*/
    position: relative;
    width: 100%;
    height: 100%;
  }

  #card-data {
    text-align: center;
    color: #6074F9;
    margin: 0;
    font-size: 18px;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 4%;
  }

  .code_128 {
    display: block;
    height: calc(100% - 80px);
    max-width: 100%;
    margin: 30px auto 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 85%;
  }
  .qr_code {
    display: block;
    max-width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 85%;
  }

  @media only screen and (max-width: 375px) {
    #barcode-image {
      /*height: calc(66vw + 52px)*/
    }
  }
</style>
