<template>
  <div class="outer-wrapper">
    <transition name="fade" mode="out-in">
      <div v-if="!siteLogoClicked" @click="siteLogoClicked = true" key="logo" class="site-logo-wrapper">
        <img class="site-logo-img" :src="`${this.$basePath}${this.$siteLogoUrl}`" alt="Site logo">
      </div>
      <div v-else key="form">
        <img class="site-logo-welcome" :src="`${this.$basePath}${this.$siteLogoUrl}`" alt="Site logo">
        <div class="container">
          <div class="content-wrapper">
            <h1>Welcome</h1>
            <p class="text text-muted">Sign in to continue</p>
            <form v-if="false" @submit.prevent="login">
              <div class="form-group">
                <label for="external_id">Login</label>
                <input type="text" v-model="externalId" class="form-control" id="external_id">
              </div>
              <button type="submit" class="login-button btn btn-primary">Login</button>
            </form>

            <form @submit.prevent="loginLdap">
              <div class="form-group">
                <label for="username">Login</label>
                <input type="text" v-model="username" class="form-control" id="username">
              </div>
              <div class="form-group">
                <label for="password">Password</label>
                <input type="password" v-model="password" class="form-control" id="password">
              </div>
              <button type="submit" class="login-button btn btn-primary">Login</button>
            </form>

            <b-alert v-model="showErrorMessage" class="text-center mt-3" variant="danger" dismissible>
              {{ errorMessage }}
            </b-alert>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
    import {AUTH_REQUEST} from '@/store/actions/auth'
    import {AUTH_REQUEST_LDAP} from '@/store/actions/auth'
    import {NOTIFICATIONS_PING, NOTIFICATIONS_INTERVAL, NOTIFICATIONS_REQUEST} from "@/store/actions/notifications";
    import {USER_SEND_DEVICE_ID} from "@/store/actions/user";

    import { mapActions } from 'vuex';
    import router from "@/router";

    export default {
        name: 'login',
        data() {
            return {
                siteLogoClicked: false,
                externalId: '',

                username: '',
                password: '',

                errorMessage: '',
                showErrorMessage: false
            }
        },
        methods: {
            ...mapActions({
              sendLdapAuthRequest: AUTH_REQUEST_LDAP,
              sendNotificationsRequest: NOTIFICATIONS_PING,
              startNotificationsInterval: NOTIFICATIONS_INTERVAL,
              sendUserDeviceId: USER_SEND_DEVICE_ID
            }),
            loginLdap () {
              this.sendLdapAuthRequest({
                username: this.username,
                password: this.password
              })
                .then(() => {
                  this.sendNotificationsRequest()
                    .then(() => {
                      this.startNotificationsInterval();
                    });

                  if (this.$environment === 'android' || this.$environment === 'ios') {
                    this.sendUserDeviceId();
                  }

                  router.push({name: 'Home'})
                })
                .catch((error) => {
                  if (error.response.status === 404) {
                    this.errorMessage = error.response.data.error
                  } else {
                    this.errorMessage = 'Something went wrong'
                  }

                  this.showErrorMessage = true
                })
            },
            login: function () {
                this.$store.dispatch(AUTH_REQUEST, this.externalId)
                    .then(() => {

                        this.$store.dispatch(NOTIFICATIONS_REQUEST)
                        router.push({name: 'Home'})
                    })
                    .catch((error) => {
                        if (error.response.status === 404) {
                            this.errorMessage = 'User not found'
                        } else {
                            this.errorMessage = 'Something went wrong'
                        }

                        this.showErrorMessage = true
                    })
            }
        },
        mounted() {
            setTimeout(() => {
                this.siteLogoClicked = true
            }, 1000)
        }
    }
</script>

<style scoped>

  .outer-wrapper {
    position: relative;
  }

  .container {
    padding: 0 20px 20px;
    margin-bottom: 20px;
  }

  .content-wrapper {
    width: 100%;
  }

  .content-wrapper p {
    margin-bottom: 45px;
  }

  .site-logo-wrapper {
    width: 100%;
    background-color: #fff;
    z-index: 10;
  }

  .site-logo-img {
    display: block;
    width: 55%;
    margin: 0 auto;
  }

  .site-logo-welcome {
    display: block;
    width: 55%;
    margin: 0 auto 50px;
  }
</style>
