<template>
  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30.2339 18.4266L25.1648 15.4999L30.2342 12.5732C30.5275 12.404 30.7413 12.1251 30.8291 11.7981C30.9167 11.4709 30.8708 11.1225 30.7016 10.8294L27.1455 4.67049C26.793 4.05978 26.0122 3.85094 25.4017 4.20327L20.3322 7.13015V1.27655C20.3322 0.571556 19.7607 0 19.0557 0H11.9441C11.2391 0 10.6675 0.571556 10.6675 1.27655V7.13015L5.59809 4.2031C5.30499 4.03391 4.95641 3.98727 4.62944 4.07561C4.30247 4.16327 4.0235 4.37705 3.85432 4.67032L0.298519 10.8291C0.129163 11.1224 0.0833771 11.4708 0.170864 11.7977C0.25852 12.1247 0.472471 12.4037 0.765738 12.5729L5.83536 15.4998L0.766078 18.4266C0.155545 18.7791 -0.0536397 19.5599 0.298859 20.1704L3.85466 26.3294C4.02401 26.6226 4.30264 26.8366 4.62978 26.9241C4.95658 27.0121 5.30533 26.9659 5.59843 26.7966L10.6675 23.87V29.7236C10.6675 30.4288 11.2391 31.0002 11.9441 31.0002H19.0557C19.7607 31.0002 20.3322 30.4288 20.3322 29.7236V23.8699L25.4014 26.7966C26.0117 27.1487 26.7925 26.9399 27.1451 26.3294L30.7011 20.1704C30.8705 19.8771 30.9162 19.5287 30.8288 19.2018C30.7409 18.8748 30.5271 18.5958 30.2339 18.4266ZM25.5724 23.9471L19.694 20.5532C19.2991 20.3251 18.8123 20.3251 18.4174 20.5532C18.0225 20.7813 17.7791 21.2025 17.7791 21.6587V28.4469H13.2206V21.6589C13.2206 21.2027 12.9773 20.7815 12.5824 20.5534C12.1875 20.3253 11.7007 20.3253 11.3058 20.5534L5.42737 23.9471L3.14813 19.9993L9.02674 16.6054C9.42162 16.3773 9.66502 15.9561 9.66502 15.4999C9.66502 15.0438 9.42162 14.6225 9.02674 14.3944L3.14779 11.0003L5.42703 7.05271L11.3058 10.4468C11.7007 10.6749 12.1875 10.6749 12.5824 10.4468C12.9773 10.2187 13.2206 9.79747 13.2206 9.34131V2.55311H17.7791V9.34114C17.7791 9.7973 18.0225 10.2186 18.4174 10.4466C18.8123 10.6747 19.2991 10.6747 19.694 10.4466L25.5728 7.05254L27.8522 11.0003L21.9734 14.3943C21.5785 14.6223 21.3351 15.0436 21.3351 15.4998C21.3351 15.9559 21.5785 16.3772 21.9734 16.6052L27.8518 19.9992L25.5724 23.9471Z"
          :fill="color"/>
  </svg>
</template>

<script>
    export default {
        name: "Health",
        props: ['color']
    }
</script>

<style scoped>

</style>