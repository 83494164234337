<template>
  <div id="image-wrapper">
    <img
    ref="photoImg"
    :src="profile.photo_url"
    @click="navigateToMainCard" alt="Photo" id="photo"
    >
    <button class="btn btn-light" id="photo-btn" @click="changeVisibleModal('photoModal',true)">
      <i class="fa fa-camera text-primary"></i>
    </button>

    <b-modal v-model="photoModal" hide-footer>
      <template v-slot:modal-title>
        Your photo has been submitted for review
      </template>
      <div id="popupPhotoContainer">
        <img
             :src="popupPhotoContent"
             id="popupImage"
             :style="{border: '3px solid ' + photoStatusColor}"
             alt="Last photo">
        <button v-if="pendingPhotoId" @click="deletePendingPhoto" id="deletePhoto" class="btn btn-light">
          <i class="fa fa-trash-alt text-primary"></i>
        </button>
        <div id="statusWrapper">
          <p class="last-photo-status" :style="{'background-color': photoStatusColor}">
            {{ popupPhotoStatus }}{{ declineReason ? '.' : '' }}<br v-if="declineReason">{{ declineReason }}
          </p>
        </div>
      </div>
      <b-button class="mt-3" variant="primary" block @click="showChangePhotoPopup">Change photo</b-button>
      <b-button class="mt-3" variant="outline-primary" block @click="changeVisibleModal('photoModal',false)">Back</b-button>
    </b-modal>

    <b-modal v-model="changePhotoModal" hide-footer>
      <template v-slot:modal-title>
        Photo requirements
      </template>
      <div class="photo-section">
        <div class="photo-content">
          <div v-if="croppedPhotoToUpload" class="photo-image-container">
            <img class="photo-image" :src="uploadedPhotoImage" alt="Photo must be here">
          </div>
          <div v-else class="photo-text">
            <p>The photo should be passport-style and meet the following requirements:</p>
            <ul>
              <li>The background should be uniform and light-colored without shadows.</li>
              <li>The background must be smooth and show no texture.</li>
              <li>The photo needs to be a front view of your full face, centered in the frame from just above the top of your
                head to your collarbone. Photos with sunglasses, hats or clothing that obscures the face are NOT acceptable.
              </li>
              <li>All headwear must be removed, unless worn for valid religious or medical reasons.</li>
              <li>Your eyes need to be open and visible. If you wear eyeglasses, there cannot be any glare.</li>
            </ul>
          </div>
        </div>
        <label class="w-100">
          <input type="file" @change="onPhotoChoose" style="position: absolute; top: -1000px"/>
          <span class="btn btn-primary btn-block">Choose photo from gallery</span>
        </label>
      </div>
      <div v-if="documentPhotoRequired" class="document-section">
        <div class="document-content">
          <div v-if="newDocumentToUpload" class="document-image-container">
            <img class="document-image" :src="uploadedDocumentImage" alt="Document must be here">
          </div>
          <div v-else class="document-text">
            <p>Please verify your identity</p>
            <ul>
              <li>Provide a valid government-issued ID</li>
              <li>This action is required only once - to verify your first photo</li>
              <li>Once identity is verified, the document will be immediately permanently deleted</li>
            </ul>
          </div>
        </div>
        <label class="w-100">
          <input type="file" @change="onDocumentChoose" style="position: absolute; top: -1000px"/>
          <span class="btn btn-primary btn-block">Choose document from gallery</span>
        </label>
      </div>
      <div v-if="isSubmitting" class="submit-preloader-container">
        <div class="submit-preloader"><div></div><div></div><div></div><div></div></div>
      </div>
      <label v-else class="w-100">
        <span v-if="croppedPhotoToUpload && documentProvidedIfRequired" @click="submitForReview" class="btn btn-primary btn-block">
          Submit for review
        </span>
      </label>
      <b-button variant="outline-danger" block @click="onCancelClick">Cancel
      </b-button>
    </b-modal>

    <b-modal v-model="previewPhotoModal" hide-footer>
      <template v-slot:modal-title>
        Preview
      </template>
      <div id="preview">
        <cropper
          v-if="previewedPhotoUrl"
          ref="cropper"
          classname="cropper"
          :src="previewedPhotoUrl"
          :stencil-props="{
            aspectRatio: 7/6
          }"
        ></cropper>
      </div>
      <b-button class="mt-3" variant="primary" block @click="submitNewPhoto">Submit for review</b-button>
      <b-button class="mt-3" variant="outline-danger" block @click="changeVisibleModal('previewPhotoModal',false)">Cancel
      </b-button>
    </b-modal>
  </div>
</template>

<script>
    import client from '@/client'
    import { mapState } from "vuex";
    import { Cropper } from 'vue-advanced-cropper'
    import 'vue-advanced-cropper/dist/style.css'
    import router from "@/router";

    export default {
        name: "ProfilePhoto",
        beforeCreate() {
            let vm = this
            client.get('/photo').then((resp) => {
                vm.popupPhotoStatus = resp.data.status
                vm.popupPhotoUrl = resp.data.url
                vm.pendingPhotoId = resp.data.id
                vm.declineReason = resp.data.decline_reason
            })
        },
        data() {
            return {
                popupPhotoStatus: '',
                popupPhotoUrl: '',
                popupPhotoContent: '',
                pendingPhotoId: null,
                previewedPhotoUrl: null,
                newPhotoToUpload: null,
                newDocumentToUpload: null,
                croppedPhotoToUpload: null,
                isSubmitting: false,
                declineReason: null,
                photoModal: false,
                changePhotoModal: false,
                previewPhotoModal: false,
            }
        },
        components: {
          Cropper
        },
        computed: {
            ...mapState({profile: state => state.user.profile}),
            photoStatusColor() {
                switch (this.popupPhotoStatus) {
                    case 'verified':
                        return '#4BD284';
                    case 'pending':
                        return '#EECA45';
                    case 'declined':
                        return '#EB5757';
                    default:
                        return 'red'
                }
            },
            uploadedPhotoImage() {
              return this.croppedPhotoToUpload ? URL.createObjectURL(this.croppedPhotoToUpload) : null;
            },
            uploadedDocumentImage() {
              return this.newDocumentToUpload ? URL.createObjectURL(this.newDocumentToUpload) : null;
            },
            documentPhotoRequired() {
              return !this.profile.has_custom_verified_photo;
            },
            documentProvidedIfRequired() {
              if (!this.documentPhotoRequired) {
                return true;
              } else {
                return !!this.newDocumentToUpload;
              }
            }
        },
        watch: {
            popupPhotoUrl() {
                let vm = this
                client.get(this.popupPhotoUrl, {responseType: 'arraybuffer'})
                    .then((response) => {
                        let image = btoa(
                            new Uint8Array(response.data)
                                .reduce((data, byte) => data + String.fromCharCode(byte), '')
                        );
                        vm.popupPhotoContent = `data:${response.headers['content-type'].toLowerCase()};base64,${image}`
                    })
            },
            popupPhotoStatus() {
                // if (this.popupPhotoStatus === 'verified') {
                //     this.$store.dispatch(USER_REQUEST)
                // }
            }
        },
        methods: {
            showChangePhotoPopup() {
                this.changeVisibleModal('photoModal',false)
                this.changeVisibleModal('changePhotoModal', true)
            },
            onDocumentChoose(e) {
                this.newDocumentToUpload = e.target.files[0];
            },
            onPhotoChoose(e) {
                this.newPhotoToUpload = e.target.files[0]
                this.previewedPhotoUrl = URL.createObjectURL(this.newPhotoToUpload)
                this.changeVisibleModal('changePhotoModal', false)
                this.changeVisibleModal('previewPhotoModal',true)
            },
            submitForReview() {
              const formData = new FormData();
              formData.append('newPhoto', this.croppedPhotoToUpload);
              if (this.documentPhotoRequired) {
                formData.append('document', this.newDocumentToUpload);
              }

              let vm = this;
              vm.isSubmitting = true;
              client.post('/photo', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }).then((resp) => {
                vm.isSubmitting = false;
                vm.popupPhotoUrl = resp.data.url
                vm.popupPhotoStatus = resp.data.status
                vm.pendingPhotoId = resp.data.id
                vm.declineReason = null

                this.changeVisibleModal('previewPhotoModal',false)
                this.changeVisibleModal('photoModal',true)
                this.onCancelClick();
              });
            },
            submitNewPhoto() {
                const { canvas } = this.$refs.cropper.getResult()
                canvas.toBlob(blob => {
                    this.croppedPhotoToUpload = blob;
                    this.changeVisibleModal('previewPhotoModal',false)
                    this.changeVisibleModal('changePhotoModal', true);
                }, 'image/jpeg', 1);
            },
            deletePendingPhoto() {
                let vm = this

                client.post('/photo/delete', {
                    id: this.pendingPhotoId
                }).then((resp) => {
                    if (resp.data.status === 'success') {
                        this.changeVisibleModal('photoModal',false)

                        let data = resp.data.newPhotoData

                        vm.popupPhotoStatus = data.status
                        vm.popupPhotoUrl = data.url
                        vm.pendingPhotoId = data.id
                    }
                })
            },
            navigateToMainCard() {
                const mainCard = this.profile.cards[0]
                router.push({name: 'Card', params: {name: mainCard.name}})
            },
            onCancelClick() {
              this.changeVisibleModal('changePhotoModal', false);
              this.newDocumentToUpload = null;
              this.newPhotoToUpload = null;
              this.croppedPhotoToUpload = null;
            },
            changeVisibleModal(name, val = false) {
              this[name] = val
            },
        },
        mounted() {
          const imageWrapper = document.getElementById('image-wrapper');
          const flipperWrapper = document.getElementById('flipper-wrapper');

          if (flipperWrapper && imageWrapper) {
            const width = flipperWrapper.clientWidth;
            flipperWrapper.style.height = width * 300 / 350 + 15 + 'px';
          }
        }
    }
</script>

<style lang="scss" scoped>
  .last-photo-status {
    font-size: 14px;
    text-align: center;
  }

  #image-wrapper {
    margin-top: 15px;
    width: 100%;
    height: 100%;
    box-shadow: 0 11px 12px -5px rgba(0, 0, 0, 0.12);
  }

  #photo {
    display: block;
    height: auto;
    max-width: 100%;
    width: 100%;
  }

  #photo-btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
    border-radius: 100%;
    width: 42px;
    height: 42px;
    padding: 0;
    line-height: 40px;
    text-align: center;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.34) !important;
  }

  #photo-btn:focus {
    box-shadow: none;
  }

  #popupPhotoContainer {
    position: relative;
  }

  #popupImage {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 auto;
    border-radius: 5px;
  }

  #statusWrapper {
    position: absolute;
    left: 50%;
    bottom: 0;
  }

  #statusWrapper p {
    position: relative;
    left: -50%;
    padding: 5px 20px;
    border-radius: 10px;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
  }

  #preview {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 10px auto;
  }

  #preview img {
    max-width: 100%;
    max-height: 400px;
  }

  .document-image {
    width: 300px;
    height: auto;
    &-container {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }
  }

  .photo-image {
    width: 300px;
    height: auto;
    &-container {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }
  }

  @media only screen and (max-width: 375px) {
    #photo {
      /*height: calc(66vw + 129px);*/
    }
  }

  // Animation
  .submit-preloader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    transform: scale(0.8);
    &-container {
      display: flex;
      justify-content: center;
    }
  }
  .submit-preloader div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #292e4e;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .submit-preloader div:nth-child(1) {
    left: 8px;
    animation: submit-preloader1 0.6s infinite;
  }
  .submit-preloader div:nth-child(2) {
    left: 8px;
    animation: submit-preloader2 0.6s infinite;
  }
  .submit-preloader div:nth-child(3) {
    left: 32px;
    animation: submit-preloader2 0.6s infinite;
  }
  .submit-preloader div:nth-child(4) {
    left: 56px;
    animation: submit-preloader3 0.6s infinite;
  }
  @keyframes submit-preloader1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes submit-preloader3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes submit-preloader2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
</style>
