<template>
  <div>
    <div class="root-header sos-header">
      <a @click="goToHome" class="back-link">
        <arrow-back/>
      </a>
      <h1>SOS call</h1>
    </div>
    <div class="sos-content">
      <h2>Type of emergency</h2>
      <div class="types-wrapper">
        <div @click="typeCLick('security')"
             class="emergency-type"
             :class="{'chosen-emergency-type': chosenType === 'security'}">
          <div class="icon-wrapper">
            <type-security :color="chosenType === 'security' ? '#FFF' : '#F96060'"/>
          </div>
          <p>Security</p></div>
        <div @click="typeCLick('health')"
             class="emergency-type"
             :class="{'chosen-emergency-type': chosenType === 'health'}">
          <div class="icon-wrapper">
            <type-health :color="chosenType === 'health' ? '#FFF' : '#F96060'"/>
          </div>
          <p>Health</p></div>
        <div @click="typeCLick('other')"
             class="emergency-type"
             :class="{'chosen-emergency-type': chosenType === 'other'}">
          <div class="icon-wrapper">
            <type-other :color="chosenType === 'other' ? '#FFF' : '#F96060'"/>
          </div>
          <p>Other</p></div>
      </div>
      <p class="info">
        Press below to send your emergency to your security team and open a chat window. On the following
        screen, you
        can authorize livestreaming.
      </p>
      <p class="info">
        By clicking below, you authorize MIDw to send your location to security and connect you with
        emergency services
        as required. Improper use of this feature is forbidden and can result fines/etc.
      </p>
      <button @click="makeCall" :disabled="!chosenType" class="send-button">CALL</button>
    </div>
  </div>
</template>

<script>
    import ArrowBack from "@/components/icons/ArrowBack";
    import Security from "@/components/icons/emergency_types/Security";
    import Health from "@/components/icons/emergency_types/Health";
    import Other from "@/components/icons/emergency_types/Other";
    import router from "@/router";

    export default {
        name: "Sos",
        components: {
            arrowBack: ArrowBack,
            typeSecurity: Security,
            typeHealth: Health,
            typeOther: Other
        },
        props: ['profile'],
        data() {
            return {
                chosenType: null
            }
        },
        methods: {
            typeCLick(typeName) {
                this.chosenType = this.chosenType === typeName ? null : typeName
            },
            makeCall() {
                const callMap = {
                  security: this.profile.sos_security_phone_number,
                  health: this.profile.sos_health_phone_number,
                  other: this.profile.sos_other_phone_number
                };
                const number = callMap[this.chosenType] || this.profile.emergency_number;
                window.open('tel:' + number);
            },
            goToHome() {
              router.push({name: 'Home'})
            },
        }
    }
</script>

<style scoped>

  .sos-content {
    overflow-y: auto;
    height: calc(100vh - 110px);
  }

  .sos-header {
    height: 100px;
    background-color: #F96060;
  }

  .sos-content {
    padding: 20px 15px;
    margin: 0 20px 0;
    transform: translateY(-30px);

    border-radius: 5px;
    background-color: #fff;
    box-shadow: 3px 3px 9px rgba(116, 116, 116, 0.28);
  }

  .sos-content h2 {
    text-align: center;
    font-size: 18px;
    line-height: 22px;
  }

  .types-wrapper {
    display: flex;
    justify-content: space-between;

    margin: 40px 0;
  }

  .emergency-type {
    width: 30%;
    height: 85px;
    padding-top: 15px;

    background: #F9F9F9;
    border: 1px solid #DFDFDF;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;

    transition: color .15s ease-in, background-color .15s ease-in;
  }

  .chosen-emergency-type {
    background-color: #F96060;
    color: #FFF;
  }

  .icon-wrapper {
    height: 36px;
  }

  .emergency-type p {
    margin-top: 3px;

    font-size: 14px;
    line-height: 17px;
  }

  .info {
    text-align: center;
    font-size: 16px;
    line-height: 25px;
  }

  .send-button {
    width: 100%;
    height: 50px;

    font-size: 18px;
    line-height: 22px;
    border: 2px solid #F96060;
    border-radius: 5px;
    color: #fff;
    background-color: #F96060;
    transition: color .15s ease-in, background-color .15s ease-in;
  }

  .send-button[disabled="disabled"] {
    opacity: 0.3;
    color: #F96060;
    background-color: #fff;
  }
</style>
