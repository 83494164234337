<template>
  <div class="colors-wrapper-outer">
    <div ref="colors_wrapper" class="colors-wrapper">
      <div v-for="(color, index) in colors"
           :key="index"
           class="security-color"
           :style="{'background-color': color, 'width': (100 / colors.length) + '%'}"></div>
    </div>
    <span ref="current_time" class="current-time">{{ currentTime }}</span>
  </div>
</template>

<script>
    export default {
        name: "SecurityColors",
        props: ['colors'],
        data() {
            return {
                currentTime: ''
            }
        },
        created() {
            setInterval(() => {
                this.currentTime = this.formatAMPM(new Date)
            }, 1)
        },
        mounted() {

            // animate current time
            let finishedGoingRight = false;
            const currentTime = this.$refs.current_time,
                colorsWrapper = this.$refs.colors_wrapper,
                speed = 2;

            setInterval(() => {

                let leftInt = parseInt(currentTime.style.left) || 0

                if (!finishedGoingRight) {
                    leftInt = leftInt + speed
                    currentTime.style.left = leftInt + 'px';
                } else {
                    leftInt = leftInt - speed
                    currentTime.style.left = leftInt + 'px';
                }

                if (leftInt >= colorsWrapper.offsetWidth - currentTime.offsetWidth) {
                    finishedGoingRight = true;
                } else if (leftInt <= 0) {
                    finishedGoingRight = false;
                }
            }, 10);
        },
        methods: {
            formatAMPM(date) {
                let hours = date.getHours();
                let minutes = date.getMinutes();
                let seconds = date.getSeconds();

                let ampm = hours >= 12 ? 'pm' : 'am';

                hours = hours % 12;
                hours = hours ? hours : 12;

                hours = hours < 10 ? '0' + hours : hours;
                minutes = minutes < 10 ? '0' + minutes : minutes;
                seconds = seconds < 10 ? '0' + seconds : seconds;

                return hours + ':' + minutes + ':' + seconds + ' ' + ampm;
            }
        }
    }
</script>
