<template>
    <b-modal id="questionnaire-modal" v-model="needShow" no-close-on-backdrop>

        <template #modal-header>
            <h2 class="text-center">COVID questionnaire:</h2>
        </template>

        <form id="covid_questionnaire">
            <div class="row"
                v-bind:class="{ 'd-none': isHidden(i) }"
                v-for="(item, i) in questions" :key="i">
                <div class="col-md-12">
                    <h3>{{ item.question }}</h3>

                    <div v-for="(answer, j) in item.answers" :key="j" class="answer-item" @click="e => answer_click(e, i, j)">
                        <input type="checkbox" class="custom-checkbox" v-bind:name="'answers[' + i + '][]'" :value="answer" :ref="'answer_' + i + j">
                        <label class="answer" >{{ answer }}</label>
                    </div>
                </div>
            </div>
        </form>

        <template #modal-footer>
            <div class="w-100">
                <div class="row pt-3 pb-3">
                    <div class="col-md-12">
                        <span>Progress: question {{ currentStep + 1 }} from {{ maxStep }}</span>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 mb-1">
                        <button class="btn btn-primary" type="button"
                            :disabled='isFirst'
                            v-on:click="decStep">Prev
                        </button>
                    </div>
                    <div class="col-md-6 mb-1">
                        <button class="btn btn-primary" type="button"
                            :disabled='isLast'
                            v-on:click="incStep">Next
                        </button>
                    </div>
                    <div class="col-md-12 pt-3">
                        <button class="btn btn-success w-100" type="button"
                            v-bind:class="{ 'd-none': !isLast }"
                            v-on:click="sendForm">Send form
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import client from '@/client'
    import { QuestionnaireResults } from "@/store/const/applications.js";

    export default {
        name: "CovidQuestionnaire",
        beforeCreate() {
            let vm = this
            client.get('/questionnaire').then((resp) => {
                let optEnabled = resp.data.enabled;
                this.questions = resp.data.questionnaire;
                let lastSend = localStorage.getItem('last_send');
                let now = this.getNowDateFormated()

                if ((optEnabled) && (now !== lastSend)) {
                    this.needShow = true;
                }
            })
        },
        methods: {
            answer_click(e, i, j) {

                e.currentTarget.classList.toggle('active')

                let refEL = 'answer_' + i + j;
                let checkboxEl = this.$refs[refEL][0];
                let checkedVal = checkboxEl.checked;
                checkedVal = !checkedVal;
                checkboxEl.checked = checkedVal;

                if (checkedVal) {
                    e.target.classList.add('active')
                } else {
                    e.target.classList.remove('active')
                }
            },
            decStep() {
                if (this.currentStep !== 0) {
                    this.currentStep--;
                }
            },
            incStep() {
                if (this.currentStep + 1 < this.maxStep) {
                    this.currentStep++;
                }
            },
            isHidden(index) {
                return !(index === this.currentStep)
            },
            getNowDateFormated() {
                let now = new Date();
                let formated = now.getDate() + '/' + now.getMonth() + '/' + now.getFullYear()
                return formated;
            },
            sendForm() {
                const el = document.getElementById('covid_questionnaire');
                const formData = new FormData(el);

                let vm = this

                client.post('/questionnaire', formData, {
                }).then((resp) => {

                    if (resp.data.success) {
                        localStorage.setItem('last_send', vm.getNowDateFormated());
                    }

                    if (resp.data.health) {
                        localStorage.setItem('health', QuestionnaireResults.QUESTIONNAIRE_RES_OK);
                    } else {
                        localStorage.setItem('health', QuestionnaireResults.QUESTIONNAIRE_RES_BAD);

                        this.$eventBus.$emit('badHealth')
                    }
                });

                this.needShow = false
            }
        },
        data() {
            return {
                isActive: false,
                needShow: false,
                currentStep: 0,
                questions: [],
            }
        },
        mounted() {
            //this.$bvModal.show('questionnaire-modal')
        },
        computed: {
            maxStep() {
                return this.questions.length;
            },
            isFirst() {
                return this.currentStep === 0
            },
            isLast() {
                return this.currentStep + 1 === this.maxStep
            }
        }
    }
</script>
