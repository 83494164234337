<template>
  <div class="news">
    <apps-header heading="News" use-custom-handler="true" @back-button-click="$emit('back-to-list')"/>
    <div class="container">
      <h2 class="news-title">{{ news.title }}</h2>
      <iframe :srcdoc="news.content"
              scrolling="no"
              onload="this.style.height = this.contentWindow.document.body.scrollHeight + 10 + 'px';"></iframe>
      <div v-if="news.link" class="news-link link">
        <a class="news-link" target="_blank" :href="news.link">Details</a>
      </div>
    </div>
  </div>
</template>

<script>
    import AppsHeader from "@/components/pages/Apps/AppsHeader";

    export default {
        name: "News",
        props: ['news'],
        components: {
            AppsHeader
        }
    }
</script>

<style lang="scss" scoped>

  .news {
    position: absolute;
    top: 0;
    background-color: #FFF;
    z-index: 10;
    width: 100%;
    &-title {
      text-align: left !important;
      padding-left: 9px;
    }
    &-link {
      width: 100%;
      padding: 10px;
    }

  }

  .container h2 {
    padding-top: 10px;
    font-size: 24px;
    text-align: center;
  }

  .container iframe {
    border: none;
    width: 100%;
  }

  .container {
    overflow-y: auto;
    height: calc(100vh - 117px);
  }

  .link {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  @media only screen and (min-width: 480px) {
    .container {
      height: 695px;
    }
  }
</style>