<template>
    <div class="announcement">
        <apps-header heading="Announcement" use-custom-handler="true" @back-button-click="$emit('back-to-list')"/>
        <div class="container">
            <h2 class="announcement-title">{{ announcement.title }}</h2>
            <p class="announcement-body">{{ announcement.body }}</p>
        </div>
    </div>
</template>

<script>
    import AppsHeader from "@/components/pages/Apps/AppsHeader";

    export default {
        name: "Announcement",
        props: ['announcement'],
        components: {
            AppsHeader
        }
    }
</script>

<style lang="scss" scoped>

    .announcement {
        position: absolute;
        top: 0;
        background-color: #FFF;
        z-index: 10;
        width: 100%;
        &-title {
            text-align: left !important;
        }
        &-link {
            width: 100%;
            padding: 10px;
        }

    }

    .container h2 {
        padding-top: 10px;
        font-size: 24px;
        text-align: center;
    }

    .container iframe {
        border: none;
        width: 100%;
    }

    .container {
        overflow-y: auto;
        height: calc(100vh - 117px);
    }

    .link {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    @media only screen and (min-width: 480px) {
        .container {
            height: 695px;
        }
    }
</style>