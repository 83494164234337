<template>
  <div>
    <div
        @click="notificationClick"
        :style="{borderColor: color}" ref="notification"
        class="notification">
      <div class="notification-icon-outer" :style="{backgroundColor: color}">
        <div class="notification-icon-inner">
          <img :src="`${this.$basePath}/notification_types/${notification.type}.svg`" alt="">
        </div>
      </div>
      <div class="notification-value">
        <h4>{{ notification.heading }}</h4>
        <p class="notification-text">{{ notification.text }}</p>
        <div class="notification-date">{{ notifyAt }}</div>
      </div>
      <div v-if="responseRequired">
        <div class="response-required">{{ wasResponded ? 'Responded' : 'Response required' }}</div>
      </div>
    </div>
    <transition name="fade">
      <button v-if="showImSafeButton" @click="imSafeClick" class="btn im-safe-btn">
        <i class="fa fa-lg fa-check-circle mr-2"/> I'm Safe
      </button>
    </transition>
  </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "Notification",
        props: ['notification', 'isUnread'],
        data() {
            return {
                // determine whether setTimeout for changing unread to read was called
                isUnreadWaiting: false,
                // is valuable only for response_required notifications
                showImSafeButton: false,
                // is valuable only for response_required notifications
                wasResponded: false,
            }
        },
        computed: {
            ...mapState({
                types: state => state.notifications.types
            }),
            color() {
                return this.types[this.notification.type].color
            },
            notifyAt() {
                const dateTime = new Date(this.notification.full_notify_at.replace(/-/g, "/"));

                // check if date is today
                const today = new Date();
                const isToday = dateTime.getDate() === today.getDate() &&
                    dateTime.getMonth() === today.getMonth() &&
                    dateTime.getFullYear() === today.getFullYear();

                let date = '';

                if (isToday) {
                    date = 'Today';
                } else {
                    const monthNames = ["January", "February", "March", "April", "May", "June",
                        "July", "August", "September", "October", "November", "December"
                    ];

                    const month = monthNames[dateTime.getMonth()];
                    const day = dateTime.getDate();
                    date = month.substr(0, 3) + ' ' + day;
                }

                const time = dateTime.toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true})

                return date + ', ' + time
            },
            responseRequired() {
                return this.types[this.notification.type].response_required
            }
        },
        mounted() {
            // after 3 seconds of notification being on screen - add this notification to read in local storage
            const vm = this

            if (vm.isUnread) {
                const observer = new IntersectionObserver(function (entries) {
                    if (entries[0].isIntersecting === true) {
                        vm.isUnreadWaiting = true

                        setTimeout(function () {
                            if (vm.isUnreadWaiting) {

                                let readIds = localStorage.getItem('read_notifications_ids')

                                const id = vm.notification.id;

                                if (readIds) {
                                    if (!readIds.split(',').includes(id.toString())) {
                                        readIds += ',' + id
                                    }
                                } else {
                                    readIds = id
                                }

                                localStorage.setItem('read_notifications_ids', readIds)
                            }
                        }, 3000)
                    } else {
                        vm.isUnreadWaiting = false
                    }
                }, {threshold: [1]});

                observer.observe(this.$refs.notification);
            }
        },
        methods: {
            notificationClick() {
                if (this.showImSafeButton) {
                    this.showImSafeButton = false
                } else if (this.responseRequired && !this.wasResponded) {
                    this.showImSafeButton = true
                }
            },
            imSafeClick() {
                this.wasResponded = true
                this.showImSafeButton = false
            }
        }
    }
</script>

<style scoped>
  .notification {
    margin: 15px 5px 5px;
    padding: 15px;
    position: relative;

    border: 2px solid #FFF;
    border-radius: 3px;
    box-shadow: 5px 5px 9px rgba(224, 224, 224, 0.5);

    display: flex;
    align-items: center;
  }

  .notification:last-child {
    margin-bottom: 30px;
  }

  .notification-icon-outer {
    width: 35px;
    height: 35px;
    margin-right: 15px;
    display: inline-block;

    border-radius: 100%;
  }

  .notification-icon-inner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .notification-value {
    flex: 1;
  }

  .notification-value h4 {
    margin-bottom: 5px;

    font-family: avenir_nextbold, sans-serif;
    font-size: 18px;
    line-height: 22px;
    color: #313131;
  }

  .notification-text {
    margin-bottom: 5px;

    font-size: 16px;
    line-height: 20px;
    color: #696969;
  }

  .notification-date {
    font-size: 14px;
    line-height: 17px;
    color: #AAAAAA;
  }

  .response-required {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 3px;

    font-size: 12px;
    line-height: 16px;
    color: #FFF;

    background: #F96060;
    border-radius: 3px 0 0;
    text-transform: uppercase;
  }

  .im-safe-btn {
    background: #2EA744;
    color: #FFF;
    font-size: 18px;

    display: block;
    width: 95%;
    margin: 0 auto;
  }
</style>