<template>
    <div class="wrapper">
        <announcements-list @show-announcements="displayAnnouncements"></announcements-list>
        <transition name="slides">
            <announcement v-if="showAnnouncement" :announcement="currentAnnouncement" @back-to-list="showList"></announcement>
        </transition>
    </div>
</template>

<script>
    import AnnouncementsList from "./AnnouncementsList";
    import Announcement from "./Announcement";

    export default {
        name: "AnnouncementsWrapper",
        props: ['profile'],
        components: {
            announcementsList: AnnouncementsList,
            announcement: Announcement
        },
        data() {
            return {
                showAnnouncement: false,
                currentAnnouncement: null
            }
        },
        methods: {
            showList() {
                this.showAnnouncement = false
            },
            displayAnnouncements(news) {
                this.currentAnnouncement = news
                this.showAnnouncement = true
            }
        }
    }
</script>

<style scoped>
    .wrapper {
        position: relative;
    }

    .slides-enter-active {
        transform: translateX(400px);
    }

    .slides-enter-active.slides-enter-to {
        transform: translateX(0);
        transition: transform .5s;
    }

    .slides-leave-active {
        transform: translateX(0);
    }

    .slides-leave-active.slides-leave-to {
        transform: translateX(400px);
        transition: transform .5s;
    }
</style>