<template>
  <div>
    <logo-header :profile="profile"/>
    <covid-questionnaire />
    <div class="container home" id="homeContainer" style="padding-bottom: 0;" ref="homeContainer">
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <div class="home-container">
            <div id="flipper-wrapper">
              <transition name="flip" mode="out-in">
<!--                <keep-alive>-->
                  <component :is="Component"/>
<!--                </keep-alive>-->
              </transition>
            </div>
            <security-colors :colors="profile.security_colors"/>
            <profile-info :profile="profile"/>
          </div>
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>
  import ProfileInfo from "@/components/parts/ProfileInfo";
  import SecurityColors from "@/components/parts/SecurityColors";
  import LogoHeader from "@/components/parts/LogoHeader";
  import CovidQuestionnaire from "@/components/parts/CovidQuestionnaire";
  import { QuestionnaireResults } from "@/store/const/applications.js";

  import { mapActions } from 'vuex';
  import { USER_REQUEST, USER_LOG_ACTION} from "@/store/actions/user";

  export default {
    name: "Home",
    props: ['profile'],
    components: {
      'logo-header': LogoHeader,
      'profile-info': ProfileInfo,
      'security-colors': SecurityColors,
      'covid-questionnaire': CovidQuestionnaire
    },
    created() {
      this.$eventBus.$on('badHealth', () => {
          this.$refs.homeContainer.classList.add('bad_health')
      })
    },
    mounted() {
      if (localStorage.getItem('health') === QuestionnaireResults.QUESTIONNAIRE_RES_BAD) {
        //this.$refs.homeContainer.classList.add('bad_health')
        this.$eventBus.$emit('badHealth')
      }
    },
    methods: {
      ...mapActions({
        fetchUserProfile: USER_REQUEST,
        logUserAction: USER_LOG_ACTION
      }),
      adjustContainerHeight() {
        const windowHeight = window.innerHeight;
        const app = document.getElementById('app');
        const homeContainer = document.getElementById('homeContainer');
        const menu = document.getElementById('menu');
        if (app && homeContainer && menu) {
          if (homeContainer.scrollHeight > windowHeight - 117 - menu.scrollHeight) {
            homeContainer.style.height = windowHeight - 117 - menu.scrollHeight + 'px';
          }
        }
      }
    },
    beforeRouteEnter(to, from, next){
      next(vm => {
        vm.fetchUserProfile()
                .then(() => {
                  vm.adjustContainerHeight();
                  vm.logUserAction('/')
                          .then(res => {
                            console.log(res.data);
                          });
                });
      });
    }
  }
</script>

<style scoped>
.home {
  height: calc(100vh - 72px - 57px);
  overflow-y: auto;
}
  .home-container {
    margin-bottom: 0;
  }

  #flipper-wrapper {
    /*height: 418px;*/
    position: relative;
    overflow-y: hidden;
  }

  .flip-leave-active {
    animation: flipOut .3s;
  }

  .flip-enter-active {
    animation: flipIn .3s;
  }

  @-webkit-keyframes flipOut {
    to {
      -webkit-transform: rotate3d(0, 1, 0, 90deg);
      transform: rotate3d(0, 1, 0, 90deg);
    }
  }

  @keyframes flipOut {
    to {
      -webkit-transform: rotate3d(0, 1, 0, 90deg);
      transform: rotate3d(0, 1, 0, 90deg);
    }
  }

  @-webkit-keyframes flipIn {
    from {
      -webkit-transform: rotate3d(0, 1, 0, 90deg);
      transform: rotate3d(0, 1, 0, 90deg);
    }
  }

  @keyframes flipIn {
    from {
      -webkit-transform: rotate3d(0, 1, 0, 90deg);
      transform: rotate3d(0, 1, 0, 90deg);
    }
  }
</style>

