<template>
  <div class="header">
    <div class="logo-wrapper" ref="logo_wrapper">
      <!-- <img v-show="showSiteLogo" :src="profile.site_logo" @click="flipSiteLogo" alt="Site logo"> -->
      <img v-show="showSiteLogo" width=70 src="janus_logo.png" @click="flipSiteLogo" alt="Site logo">
      <h2 v-show="!showSiteLogo" @click="flipSiteLogo" class="header-main-id">{{ profile.unique_id }}</h2>
    </div>
    <router-link :to="{name: 'Sos'}" class="sos" v-if="isSosMenuActive">
      <i class="fa fa-phone-alt"/> <strong>SOS</strong>
    </router-link>
  </div>
</template>

<script>
    export default {
        name: "Header",
        props: [
            'profile'
        ],
        data() {
            return {
                showSiteLogo: true,
            }
        },
        methods: {
            flipSiteLogo() {

                const logoWrapper = this.$refs.logo_wrapper

                logoWrapper.classList.add('rotateOut')
            },
        },
        computed: {
          isSosMenuActive() {
            return this.profile.sos ? true : false;
          }
        },
        mounted() {
            const logoWrapper = this.$refs.logo_wrapper
            const vm = this

            logoWrapper.addEventListener('animationend', function (event) {

                const animationName = event.animationName;

                if (animationName === 'rotateOut') {

                    vm.showSiteLogo = !vm.showSiteLogo

                    logoWrapper.classList.remove('rotateOut')
                    logoWrapper.classList.add('rotateIn')

                } else if (animationName === 'rotateIn') {

                    logoWrapper.classList.remove('rotateIn')
                }
            })
        }
    }
</script>

<style>

  @keyframes rotateOut {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(90deg);
    }
  }

  @keyframes rotateIn {
    from {
      transform: rotateY(270deg);
    }
    to {
      transform: rotateY(360deg)
    }
  }

  .rotateIn {
    animation-name: rotateIn;
    animation-duration: .25s;
  }

  .rotateOut {
    animation: rotateOut;
    animation-duration: .25s;
  }

  .logo-wrapper {
    height: 50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 35%;
  }

  .logo-wrapper > img {
    max-width: 100%;
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    box-shadow: 0 2px 10px rgba(227, 227, 227, 0.5);
  }

  .header-main-id {
    font-family: avenir_nextbold, sans-serif;
    font-size: 17px;
    margin: 15px 0 15px 7px;
  }

  .sos {
    display: flex;
    justify-content: center;
    width: 45%;
    height: 52px;
    padding: 10px 35px 6px;
    color: white;
    font-size: 17pt;
    background-color: #EE6363;
    border-radius: 50px;
    position: relative;
  }

  @media only screen and (max-width: 370px) {
    .header-main-id {
      font-size: 15px;
    }

    .sos {
      padding: 6px 15px;
    }
  }

  .sos strong {
    font-family: avenir_nextbold, sans-serif;
  }

  .sos i {
    margin-right: 7px;
    padding-top: 5px;
    transform: scale(0.7);
  }

  .sos:hover {
    text-decoration: none;
    color: white;
  }
</style>
