<template>
    <div>
        <apps-header heading="Announcements"/>
        <div class="container">
            <div v-for="(item, $index) in list"
                 :key="$index"
                 @click="$emit('show-announcements',item)"
                 class="announcements-item with-forward-arrow">

                <img v-if="item.enclosure && item.enclosure.url" :src="item.enclosure.url" alt="Preview" class="preview">
                <img v-else src="/images/no_image.png" alt="No image" class="preview">
                <div class="title-wrapper">
                    <div class="title">{{ item.title }}</div>
                    <div class="date">{{ (new Date(item.updated_at)).toString().trim().substr(4,17) }}</div>
                </div>
            </div>
            <infinite-loading @infinite="infiniteHandler">
                <template v-slot:no-results>
                    <div class="announcements-list-error" v-if="error">
                        {{ error }}
                    </div>
                    <div v-else>
                        No results found
                    </div>
                </template>
                <template v-slot:no-more>
                    <div></div>
                </template>
            </infinite-loading>
        </div>
    </div>
</template>

<script>
    import InfiniteLoading from 'vue-infinite-loading';
    import AppsHeader from "@/components/pages/Apps/AppsHeader";
    import client from '@/client';

    export default {
        name: "AnnouncementsList",
        props: ['profile'],
        components: {
            InfiniteLoading,
            appsHeader: AppsHeader
        },
        data() {
            return {
                page: 1,
                list: [],
                error: null
            }
        },
        methods: {
            infiniteHandler($state) {

                client.get('/announcements')
                    .then(res => {
                        const data = res.data;
                        this.list.push(...data.announcements);
                        $state.loaded();
                        $state.complete();
                    })
                    .catch(err => {
                        console.log(err);
                        this.error = 'Unable to parse the url specified, please contact your organization';
                        $state.complete();
                    })

                /*
                  API query logic with pages count. If using, change "enclosure.url" to "image" (compatible to API data)

                  */
                // client.get('/announcements', {
                //     params: {
                //         page: this.page,
                //     },
                // }).then(({data}) => {
                //     if (data.error) {
                //       this.error = data.error
                //     }
                //     if (data.items[0]) {
                //         this.page += 1;
                //         this.list.push(...data.items);
                //         $state.loaded();
                //     } else {
                //         $state.complete();
                //     }
                // });
            },
        },
    }
</script>

<style scoped>

    .container {
        overflow-y: auto;
        height: calc(100vh - 117px);
    }

    .announcements-item {
        position: relative;
        padding: 5px 30px 5px 5px;
        max-height: 80px;
        margin: 5px 0;
        display: flex;

        background: #FFFFFF;
        border: 1px solid #F0F0F0;
        box-sizing: border-box;
        box-shadow: 0 2px 10px rgba(227, 227, 227, 0.5);
        cursor: pointer;
    }

    .announcements-item:first-child {
        margin-top: 22px;
    }

    .announcements-item:last-child {
        margin-bottom: 30px;
    }

    .preview {
        height: 70px;
    }

    .title-wrapper {
        padding-left: 10px;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
    }

    .title {
        position: relative;
        font-size: 14px;
        line-height: 1.2em;
        overflow: hidden;
    }

    .title:after {
        content: "";
        text-align: right;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 20%;
        height: 1.2em;
        background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
    }

    .date {
        font-size: 14px;
        color: #AAAAAA;
    }

    .announcements-list-error {
        padding: 10px;
        text-align: center;
    }

    @media only screen and (min-width: 480px) {
        .container {
            height: 695px;
        }
    }
</style>