<template>
  <div>
    <apps-header :withCalendarFilter="calendarLoaded" heading="Calendar"/>
    <div class="datepicker">
      <date-picker
              v-show="false"
              v-model="date"
              :range="false"
              :editable="false"
              value-type="date"
      ></date-picker>
    </div>
    <div class="container">
      <div v-for="(event, $index) in list"
           :key="$index"
           @click="$emit('show-event',event)"
           class="event with-forward-arrow">
        <div class="event-title">{{ event.summary }}</div>
        <div class="date">{{ event.start.toDateString() }}</div>
      </div>
      <infinite-loading ref="infiniteLoading" @infinite="infiniteHandler">
        <template v-slot:no-results>
          <div class="event-list-error" v-if="error">
            {{ error }}
          </div>
          <div v-else>
            No results found
          </div>
        </template>
        <template v-slot:no-more>
          <div class="event-list-error" v-if="error">
            {{ error }}
          </div>
          <div v-else></div>
        </template>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
    import InfiniteLoading from 'vue-infinite-loading';
    import client from '@/client';
    import moment from 'moment'
    import AppsHeader from "@/components/pages/Apps/AppsHeader";
    import DatePicker from 'vue-datepicker-next';
    import 'vue-datepicker-next/index.css';
    import { mapState, mapGetters, mapActions } from 'vuex';
    import { async } from 'node-ical';
    import { CHANGE_CALENDAR_FILTER_VALUE } from "@/store/actions/ui";
    import { CALENDAR_VALUE } from "@/store/getters/ui";

    export default {
        name: "EventsList",
        props: ['profile'],
        mounted() {
            this.adjustCalendarWidthToInputWidth();
            document.addEventListener('resize', () => {
              this.adjustCalendarWidthToInputWidth();
            });
        },
        components: {
            InfiniteLoading,
            AppsHeader,
            DatePicker
        },
        data() {
            return {
                page: 1,
                list: [],
                parsed: [],
                date: null,
                dateRange: [null, null],
                error: null,
                usingFilterToChangeDate: false,
                calendarLoaded: false
            }
        },
        computed: {
          ...mapState({
            url: state => state.user.profile.calendar_file_url
          }),
          ...mapGetters({
            calendarFilterValue: CALENDAR_VALUE
          })
        },
        methods: {
          infiniteHandler($state) {
            if (this.url) {
              try {
                let icalAsync = async;
                let calendarUrl = this.url;
                const CORS_PROXY = this.$apiBaseURL + '/proxy';

                const apiUrl = new URL(this.$apiBaseURL);
                const fileUrl = new URL(this.url);

                if (fileUrl.origin != apiUrl.origin) {
                  calendarUrl = CORS_PROXY + '?url=' + this.url;
                }

                icalAsync.fromURL(calendarUrl, {}, (err, data) => {
                  if (err) {
                    this.error = 'Unable to parse calendar file from URL specified. Please contact your organization.';
                    $state.loaded();
                    $state.complete();
                  } else {
                    for (let k in data) {
                      const ev = data[k];
                      if (data[k].type == 'VEVENT') {
                        this.parsed.push(ev);
                      }
                    }
                    this.list = this.parsed.filter(item => true);
                    $state.loaded();
                    $state.complete();
                    this.calendarLoaded = true;
                  }
                });
              } catch (e) {
                console.log(e);
                this.error = 'Unable to parse calendar file. Contact your organization';
                $state.complete();
              }
            } else {
              this.error = 'No calendar file URL specified. Please contact your organization';
              $state.complete();
            }


            /* API functionality fetch */
            // this.fetchAllEventsForDateRange().then(({data}) => {
            //   if (data.error) {
            //     this.error = data.error;
            //   }
            //   if (data.events[0]) {
            //     this.page++
            //     this.list.push(...data.events)
            //     $state.loaded()
            //   } else {
            //     $state.complete()
            //   }
            // });
          },
          fetchAllEventsForDateRange() {
            // return client.get('/calendar', {
            //   params: {
            //     page: this.page,
            //     date_from: this.dateRange[0],
            //     date_to: this.dateRange[1]
            //   }
            // })

            console.log('fetch all events for date range');
            console.log(this.dateRange[0]);
            console.log(this.dateRange[1]);
            if (this.dateRange[0] || this.dateRange[1]) {
              this.list = [...this.parsed.filter(item => {
                let good = true;

                const isoStringCut = item.start.toString().substr(0, 15);
                const isoString = (new Date(isoStringCut + ' UTC+0000')).toISOString().substr(0, 10);

                if (this.dateRange[0]) {
                  const time1 = (new Date(this.dateRange[0] + ' 00:00:00 UTC+0000')).getTime() + 1 * 24 * 60 * 60 * 1000;
                  const isoString1 = new Date(time1).toISOString().substr(0, 10);
                  if (isoString1 > isoString) {
                    good = false;
                  }
                }

                if (this.dateRange[1]) {
                  const time2 = (new Date(this.dateRange[1] + ' 00:00:00 UTC+0000')).getTime() + 1 * 24 * 60 * 60 * 1000;
                  const isoString2 = new Date(time2).toISOString().substr(0, 10);

                  if (isoString2 < isoString) {
                    good = false;
                  }
                }

                return good;
              })];
            }
          },
          adjustCalendarWidthToInputWidth() {
            const styleID = 'calendar_style';
            const existingCalendarStyle = document.getElementById(styleID);
            if (existingCalendarStyle) {
              existingCalendarStyle.parentNode.removeChild(existingCalendarStyle);
            }
            const style = document.createElement("style");
            style.setAttribute('id', styleID);
            style.appendChild(document.createTextNode(""));
            document.head.appendChild(style);
            const calendarInput = document.getElementsByClassName('mx-input-wrapper')[0];
            style.sheet.insertRule(`.mx-calendar { width: ${calendarInput.scrollWidth - 2}px !important; }`, 0);
          },
          eventsReset() {
            this.list = [...this.parsed.filter(item => true)];
          },
          ...mapActions({
            changeCalendarFilterValue: CHANGE_CALENDAR_FILTER_VALUE
          }),
          getMonth(index) {
            const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Sep', 'Oct', 'Nov', 'Dec'];
            return month[index];
          },
          getPreviousMonth(index) {
            if (index === 0) {
              return 11;
            }
            return index - 1;
          },
          getNextMonth(index) {
            if (index === 11) {
              return 0;
            }
            return index - 1;
          }
        },
        watch: {
          calendarFilterValue(val) {
            const actionMap = {
              ['all']: () => {
                if (this.date === null) {
                  this.date = false;
                } else {
                  this.date = null;
                }
              },
              ['this_year']: () => {
                const d = new Date();
                const year = d.getFullYear();
                this.dateRange = [
                   new Date(`1 Jan ${year}`),
                   new Date(`31 Dec ${year}`)
                ];
              },
              ['this_month']: () => {
                const d = new Date();
                const month = this.getMonth(d.getMonth());
                const nextMonth = this.getNextMonth(d.getMonth())
                const year = d.getFullYear();
                const year2 = nextMonth === 0 ? year+1 : year;
                this.dateRange = [
                  new Date(`1 ${month} ${year}`),
                  new Date((new Date(`1 ${nextMonth} ${year2}`)).getTime() - 60 * 60 * 24 * 1000)
                ];
              },
              ['this_week']: () => {
                this.dateRange = [
                  moment().startOf('week').add(1, 'days').toDate(),
                  moment().endOf('week').add(1, 'days').add(1, 'minute').toDate()
                ]
              },
              ['this_weekend']: () => {
                this.dateRange = [
                  moment().endOf('week').add(-1, 'days').add(1, 'minute').toDate(),
                  moment().endOf('week').add(1, 'days').add(1, 'minute').toDate()
                ]
              },
              ['today']: () => {
                this.date = new Date();
              }
            };

            if (actionMap[val]) {
              this.usingFilterToChangeDate = true;
              actionMap[val]();
            }
          },
          date (val) {
            if (!val) {
              this.eventsReset();
              this.usingFilterToChangeDate = false;
              return;
            }
            if (!this.usingFilterToChangeDate) {
              this.changeCalendarFilterValue('date');
            }
            this.usingFilterToChangeDate = false;

            this.page = 1;
            this.list = [];
            this.dateRange[0] = val.toISOString().substr(0,10);
            this.dateRange[1] = val.toISOString().substr(0,10);

            this.fetchAllEventsForDateRange();

            // this.fetchAllEventsForDateRange().then(({data}) => {
            //   this.$refs.infiniteLoading.stateChanger.reset();
            //   if (data.events[0]) {
            //     this.list.push(...data.events);
            //   }
            // });
          },
          dateRange (val) {
            if (!val) {
              this.eventsReset();
              return;
            }

            if (!this.usingFilterToChangeDate) {
              this.changeCalendarFilterValue('date_range');
            }
            this.usingFilterToChangeDate = false;

            this.dateRange[0] = val[0].toISOString().substr(0,10);
            this.dateRange[1] = val[1].toISOString().substr(0,10);

            this.page = 1;
            this.list = [];
            this.fetchAllEventsForDateRange();

            // this.fetchAllEventsForDateRange().then(({data}) => {
            //   this.$refs.infiniteLoading.stateChanger.reset();
            //   if (data.events[0]) {
            //     this.list.push(...data.events);
            //   }
            // })
          }
        }
    }
</script>

<style scoped>

  .container {
    overflow-y: auto;
    height: calc(100vh - 117px);
  }

  .event {
    position: relative;
    padding: 10px 30px 35px 10px;
    margin: 5px 0;
    min-height: 80px;

    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    box-shadow: 0 2px 10px rgba(227, 227, 227, 0.5);
    cursor: pointer;
  }

  .event:first-child {
    margin-top: 22px;
  }

  .event:last-child {
    margin-bottom: 30px;
  }

  .event-title {
    font-size: 16px;
    line-height: 1.2em;
  }

  .date {
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: 14px;
    color: #AAAAAA;
  }

  .no-results {
    color: rgb(102, 102, 102);
    font-size: 14px;
    padding: 10px 0;
  }

  .event-list-error {
    padding: 10px;
    text-align: center;
  }

  @media only screen and (min-width: 480px) {
    .container {
      height: 695px;
    }
  }
</style>
