<template>
  <svg width="33" height="31" viewBox="0 0 33 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32.6343 26.0454L18.8843 2.21204C18.4085 1.38887 17.5184 0.833374 16.5 0.833374C15.4816 0.833374 14.5915 1.38887 14.1157 2.21204L0.36575 26.0454C0.133833 26.4487 0 26.9171 0 27.4167C0 28.9347 1.232 30.1667 2.75 30.1667H30.25C31.768 30.1667 33 28.9347 33 27.4167C33 26.9171 32.8671 26.4487 32.6343 26.0454ZM2.74817 27.4195L16.4972 3.58337H16.5L16.5018 3.58612L30.25 27.4167L2.74817 27.4195ZM16.5 21C15.488 21 14.6667 21.8214 14.6667 22.8334C14.6667 23.8463 15.4871 24.6667 16.5 24.6667C17.5129 24.6667 18.3333 23.8454 18.3333 22.8334C18.3333 21.8214 17.5129 21 16.5 21ZM14.6667 12.75C14.6667 12.8545 14.6758 12.9581 14.6923 13.058L15.5953 18.4041C15.6695 18.8376 16.0462 19.1667 16.5 19.1667C16.9538 19.1667 17.3305 18.8376 17.4038 18.404L18.3067 13.058C18.3242 12.9581 18.3333 12.8545 18.3333 12.75C18.3333 11.738 17.5129 10.9167 16.5 10.9167C15.488 10.9167 14.6667 11.738 14.6667 12.75Z"
          :fill="color"/>
  </svg>
</template>

<script>
    export default {
        name: "Other",
        props: ['color']
    }
</script>

<style scoped>

</style>