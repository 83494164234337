<template>
  <div id="menu">
    <div id="items-container">
      <div class="menu-item">
        <div class="card-link drop-menu"
             style="cursor: pointer; -webkit-user-select: none;"
             :class="{'router-link-active': cardsMenuIsShown || onCardOrHomePage, 'show-cards-menu': cardsMenuIsShown}"
        >
          <div @click="idCardClick">
            <card-item iconName="main" :color="cardsMenuIsShown || onCardOrHomePage ? 'white' : 'gray'"/>
            <span>ID card</span>
          </div>
          <div ref="menu_items"
               class="items"
               @click="hideCardsMenu"
               :style="{visibility: cardsMenuIsShown ? 'visible' : 'hidden'}">
            <router-link v-for="card in cardsExceptMain"
                         :key="card.name"
                         :to="{name: 'Card', params: {name: card.name}}"
                         class="card-link item">
              <card-item iconName="other"
                         :display-name="card.name"
                         color="blue"/>
            </router-link>
            <router-link :to="{name: 'Home'}"
                         class="card-link item">
              <card-item iconName="main"
                         :display-name="mainCardName"
                         color="blue"/>
            </router-link>
          </div>
        </div>
      </div>

      <div class="menu-item">
        <router-link :to="{ name: 'Apps'}" class="card-link">
          <i class="fa fa-th-large"></i>
          <span>Apps</span>
        </router-link>
      </div>

      <div class="menu-item" v-if="isSosMenuActive">
        <router-link :to="{ name: 'Sos'}" exact class="card-link">
          <i class="fa fa-phone-alt"></i>
          <span>SOS</span>
        </router-link>
      </div>

      <div class="menu-item position-relative">
        <router-link :to="{ name: 'Notifications'}" exact class="card-link">
          <i class="fa fa-bell"></i>
          <span>Alerts</span>
          <div v-if="unreadNotificationsCount > 0" class="unread-counter">{{ unreadNotificationsCount }}</div>
        </router-link>
      </div>

      <div class="menu-item">
        <router-link :to="{ name: 'Settings'}" exact class="card-link">
          <i class="fa fa-cog"></i>
          <span>Settings</span>
        </router-link>
      </div>

    </div>
  </div>
</template>

<script>
    import CardMenuItem from "@/components/parts/CardMenuItem";
    import {mapState} from "vuex";
    import router from "@/router";

    export default {
        name: "Menu",
        props: ['profile'],
        components: {
            'card-item': CardMenuItem
        },
        data() {
            return {
                cardsMenuIsShown: false,
            }
        },
        mounted() {
        },
        computed: {
            ...mapState({
                notifications: state => state.notifications.notifications,
            }),
            unreadNotificationsCount() {
              return this.notifications && this.notifications.unread ?
                  this.notifications.unread.length : 0;
            },
            onCardOrHomePage() {
                return this.$route.name === 'Home' || this.$route.name === 'Card'
            },
            cardsExceptMain() {
                return this.profile.cards ? this.profile.cards.slice(1) : [];
            },
            mainCardName() {

                const mainCard = this.profile.cards ?
                  this.profile.cards[0] : null;

                return mainCard ? mainCard.name : null;
            },
            isSosMenuActive() {
              return this.profile.sos ? true : false;
            }
        },
        watch: {
            $route() {
                this.hideCardsMenu()
            }
        },
        methods: {
            idCardClick() {
                if (this.cardsExceptMain.length !== 0) {
                    // show card menu
                    this.$eventBus.$emit('softShadowOn')
                    this.cardsMenuIsShown = true
                } else {
                    // go to home
                    router.push({name: 'Home'})
                }
            },
            hideCardsMenu() {
                this.$eventBus.$emit('shadowsOff')
                this.cardsMenuIsShown = false
            },
        },
    }
</script>

<style>

  .unread-counter {
    position: absolute;
    right: 22px;
    top: 0;
    width: 15px;
    height: 15px;

    font-size: 11px;
    line-height: 16px;
    color: #FFF;

    background-color: #EB5757;
    border-radius: 100%;
  }
</style>
