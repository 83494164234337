<template>
  <div class="event">
    <apps-header heading="Event" use-custom-handler="true" :withAddToCalendar="true" @back-button-click="$emit('back-to-list')"/>
    <div class="container">
      <h2 v-html="event.header"/>
      <div class="datum"><strong>Dates:</strong> {{ dates }}</div>
      <div v-if="event.description" class="datum"><strong>Description:</strong> {{ event.description }}</div>
      <div v-if="event.location" class="datum"><strong>Location:</strong> {{ event.location }}</div>
      <div v-if="event.organizer" class="datum"><strong>Organizer:</strong> {{ event.organizer }}</div>
    </div>
  </div>
</template>

<script>
    import '../../../../lib/add-to-calendar.js';
    import AppsHeader from "@/components/pages/Apps/AppsHeader";
    import { mapActions } from 'vuex'
    import { SET_CURRENT_EVENT_LINKS } from "../../../../store/actions/ui";

    export default {
        name: "Event",
        props: ['event'],
        components: {
            AppsHeader
        },
        computed: {
            dates() {
                return this.event.start.toDateString() + ' - ' + this.event.end.toDateString();
            }
        },
        methods: {
          ...mapActions({
            setCurrentEventLinks: SET_CURRENT_EVENT_LINKS
          })
        },
        mounted() {
          const event = this.event;
          const caldata = addToCalendarData({
            data: {
              title: event.summary || 'event',
              start: event.start || null,
              end: event.end || null,
              description: event.description || null,
              location: event.location || null
            }
          });
          this.setCurrentEventLinks(caldata);
        }
    }
</script>

<style scoped>

  .event {
    position: absolute;
    width: 100%;
    top: 0;
    background-color: #FFF;
    z-index: 10;
  }

  .container h2 {
    padding-top: 10px;
    font-size: 24px;
    text-align: center;
  }

  .container iframe {
    border: none;
    width: 100%;
  }

  .container {
    overflow-y: auto;
    height: calc(100vh - 117px);
  }

  .datum {
    padding-top: 10px;
  }

  @media only screen and (min-width: 480px) {
    .container {
      height: 695px;
    }
  }

  /* slides down */
  .slides-down-enter-active {
    transform: translateY(100vh);
  }

  .slides-down-enter-active.slides-down-enter-to {
    transform: translateY(0);
    transition: transform .5s;
  }

  .slides-down-leave-active {
    transform: translateY(0);
  }

  .slides-down-leave-active.slides-down-leave-to {
    transform: translateY(100vh);
    transition: transform .5s;
  }
</style>