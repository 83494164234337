import {
    USER_ERROR,
    USER_REQUEST,
    USER_SUCCESS,
    USER_SEND_DEVICE_ID,
    USER_LOG_ACTION
} from '../actions/user'
import {AUTH_LOGOUT} from '../actions/auth'
import client from '@/client'

const state = { status: '', profile: {} };

const getters = {
    getProfile: state => state.profile,
    isProfileLoaded: state => !!state.profile.first_name,
    getLoadingStatus: state => state.status
};

const actions = {
    [USER_REQUEST]: ({commit, dispatch}) => {
        return new Promise((resolve) => {
            commit(USER_REQUEST)

            // trying to get profile from localstorage
            const jsonProfile = localStorage.getItem('profile')
            if (jsonProfile !== null) {
                commit(USER_SUCCESS, JSON.parse(jsonProfile))
                resolve()
            } else {
                // if no profile in localstorage - load it from API
                client.get('/profile-data')
                    .then(resp => {
                        // localStorage.setItem('profile', JSON.stringify(resp.data))
                        commit(USER_SUCCESS, resp.data)
                        resolve()
                    })
                    .catch(() => {
                        commit(USER_ERROR)
                        dispatch(AUTH_LOGOUT).then(() => {
                            location.reload();
                        })
                    })
            }
        })
    },
    [USER_SEND_DEVICE_ID]: () => {
        window.plugins.OneSignal.getIds(function(ids) {
            client.post('/device', {
                player_id: ids.userId,
                push_token: ids.pushToken
            }).then(res => {
                alert('Device ID has been successfully sent');
            }).catch(err => {
                alert(err);
                alert('Error. Failed to send device ID');
            });
        });
    },
    [USER_LOG_ACTION]: ({commit, dispatch}, action) => {
        return client.get('/log', { params: { action } });
    }
};

const mutations = {
    [USER_REQUEST]: (state) => {
        state.status = 'loading'
    },
    [USER_SUCCESS]: (state, resp) => {
        state.status = 'success';
        state.profile = resp;
    },
    [USER_ERROR]: (state) => {
        state.status = 'error'
    },
    [AUTH_LOGOUT]: (state) => {
        state.profile = {}
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
}
