<template>
  <div class="root-header apps-header">
    <a @click="onLinkClick" class="back-link">
      <arrow-back/>
    </a>
    <h1>{{ heading }}</h1>
    <div
            @click="showCalendarFilter"
            v-if="this.withCalendarFilter"
            :style="'position: absolute; right: 20px; color: #fff; top: 0;height: 100%;align-items: center;font-size: 20px;display: flex;'"
    >
      <filter-icon :height="40"></filter-icon>
    </div>
    <div
            v-if="withAddToCalendar"
            @click="showAddToCalendarMenu"
            :style="'position: absolute; right: 20px; color: #fff; top: 0;height: 100%;align-items: center;font-size: 20px;display: flex;'"
    >
      <add-to-calendar-button></add-to-calendar-button>
    </div>
  </div>
</template>

<script>
    import ArrowBack from "@/components/icons/ArrowBack";
    import AddToCalendarButton from "./Calendar/AddToCalendarButton";
    import FilterIcon from "./Calendar/FilterIcon";
    import { mapActions } from 'vuex';
    import { SHOW_CALENDAR_FILTER, SHOW_ADD_TO_CALENDAR_MENU } from "../../../store/actions/ui";
    import router from "@/router";

    export default {
        name: "AppsHeader",
        props: ['heading', 'useCustomHandler', 'withCalendarFilter', 'withAddToCalendar'],
        components: {
            arrowBack: ArrowBack,
            filterIcon: FilterIcon,
            addToCalendarButton: AddToCalendarButton
        },
        methods: {
            onLinkClick() {
                if (this.useCustomHandler) {
                    this.$emit('back-button-click')
                } else {
                    router.push({name: 'Apps'})
                }
            },
            ...mapActions({
              showCalendarFilter: SHOW_CALENDAR_FILTER,
              showAddToCalendarMenu: SHOW_ADD_TO_CALENDAR_MENU
            })
        }
    }
</script>

<style lang="scss">
  .apps-header {
    background: #6074f9;

    & h1 {
      white-space: nowrap;
    }
  }
</style>
