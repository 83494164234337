import {
    IS_CALENDAR_FILTER_SHOWN,
    CALENDAR_VALUE,
    IS_ADD_TO_CALENDAR_MENU_SHOWN,
    CURRENT_EVENT_LINKS
} from "../getters/ui";
import {
    SHOW_CALENDAR_FILTER,
    HIDE_CALENDAR_FILTER,
    CHANGE_CALENDAR_FILTER_VALUE,
    SHOW_ADD_TO_CALENDAR_MENU,
    HIDE_ADD_TO_CALENDAR_MENU,
    SET_CURRENT_EVENT_LINKS
} from '../actions/ui'
import {
    SHOW_CALENDAR_FILTER_MUTATION,
    HIDE_CALENDAR_FILTER_MUTATION,
    CHANGE_CALENDAR_FILTER_VALUE_MUTATION,
    SHOW_ADD_TO_CALENDAR_MENU_MUTATION,
    HIDE_ADD_TO_CALENDAR_MENU_MUTATION,
    SET_CURRENT_EVENT_LINKS_MUTATION
} from "../mutations/ui";

const state = {
    showCalendarFilter: false,
    filterValue: null,
    calendarFilterSelectedDate: null,
    calendarFilterSelectedDateRange: null,
    isAddToCalendarMenuShown: false,
    currentEventLinks: null
};

const getters = {
    [IS_CALENDAR_FILTER_SHOWN]: state => state.showCalendarFilter,
    [CALENDAR_VALUE]: state => state.filterValue,
    [IS_ADD_TO_CALENDAR_MENU_SHOWN]: state => state.isAddToCalendarMenuShown,
    [CURRENT_EVENT_LINKS]: state => state.currentEventLinks
};

const actions = {
    [SHOW_CALENDAR_FILTER]: context => {
        context.commit(SHOW_CALENDAR_FILTER_MUTATION);
    },
    [HIDE_CALENDAR_FILTER]: context => {
        context.commit(HIDE_CALENDAR_FILTER_MUTATION)
    },
    [CHANGE_CALENDAR_FILTER_VALUE]: (context, payload) => {
        context.commit(CHANGE_CALENDAR_FILTER_VALUE_MUTATION, payload)
    },
    [SHOW_ADD_TO_CALENDAR_MENU]: (context) => {
        context.commit(SHOW_ADD_TO_CALENDAR_MENU_MUTATION);
    },
    [HIDE_ADD_TO_CALENDAR_MENU]: (context) => {
        context.commit(HIDE_ADD_TO_CALENDAR_MENU_MUTATION);
    },
    [SET_CURRENT_EVENT_LINKS]: (context, payload) => {
        context.commit(SET_CURRENT_EVENT_LINKS_MUTATION, payload);
    }
};

const mutations = {
    [SHOW_CALENDAR_FILTER_MUTATION]: state => {
        state.showCalendarFilter = true;
    },
    [HIDE_CALENDAR_FILTER_MUTATION]: state => {
        state.showCalendarFilter = false;
    },
    [CHANGE_CALENDAR_FILTER_VALUE_MUTATION]: (state, payload) => {
        state.filterValue = payload
    },
    [SHOW_ADD_TO_CALENDAR_MENU_MUTATION]: state => {
        state.isAddToCalendarMenuShown = true;
    },
    [HIDE_ADD_TO_CALENDAR_MENU_MUTATION]: state => {
        state.isAddToCalendarMenuShown = false;
    },
    [SET_CURRENT_EVENT_LINKS_MUTATION]: (state, payload) => {
        state.currentEventLinks = payload;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
}