export default {
    ['web']: () => {
        return './';
    },
    ['android']: () => {
        return '/android_asset/www/';
    },
    ['ios']: () => {
        return (typeof cordova !== 'undefined' && cordova.file) ? cordova.file.applicationDirectory + 'www/' : './';
    }
};