import { createStore } from 'vuex'
import user from './modules/user'
import auth from './modules/auth'
import notifications from './modules/notifications'
import ui from './modules/ui'

const debug = process.env.NODE_ENV !== 'production'

const store = createStore({ strict: debug })

store.registerModule('user', user)
store.registerModule('auth', auth)
store.registerModule('notifications', notifications)
store.registerModule('ui', ui)

export default store
