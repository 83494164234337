<template>
  <div>
    <div class="root-header notifications-header">
      <a @click="goToHome" class="back-link">
        <arrow-back/>
      </a>
      <h1>Alerts</h1>
    </div>
    <div class="container">
      <div class="notifications-container">
        <h3 v-if="unreadNotifications" class="notifications-label">Unread Alerts</h3>
        <notification v-for="unreadNotification in unreadNotifications"
                      :notification="unreadNotification"
                      :isUnread="true"
                      :key="unreadNotification.id"
        />
        <h3 v-if="readNotifications" class="notifications-label">Other Alerts</h3>
        <notification v-for="otherNotification in readNotifications"
                      :notification="otherNotification"
                      :isUnread="false"
                      :key="otherNotification.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
    import {mapState, mapActions} from "vuex";
    import Notification from "@/components/parts/Notification";
    import ArrowBack from "@/components/icons/ArrowBack";
    import { NOTIFICATIONS_PING } from "@/store/actions/notifications";
    import router from "@/router";

    export default {
        name: "Notifications",
        props: ['profile'],
        components: {
            notification: Notification,
            arrowBack: ArrowBack
        },
        data() {
            return {
                readNotifications: null,
                unreadNotifications: null
            }
        },
        methods: {
          ...mapActions({
            ping: NOTIFICATIONS_PING
          }),
          goToHome() {
            router.push({name: 'Home'})
          },
        },
        computed: {
            ...mapState({
                notifications: state => state.notifications.notifications,
            }),
        },
        watch: {
            notifications(val) {
                if (val) {
                    this.readNotifications = val.read;
                    this.unreadNotifications = val.unread;
                }
            }
        },
        created() {
            if (this.notifications) {
                this.readNotifications = this.notifications.read
                this.unreadNotifications = this.notifications.unread
            }
        }
    }
</script>

<style scoped>
  .notifications-header {
    background: #6074F9;
  }

  .notifications-container {
    overflow-y: auto;
    height: calc(100vh - 127px);
  }

  @media only screen and (min-width: 480px) {
    .notifications-container {
      /*height: 695px;*/
      height: calc(100vh - 127px);
    }
  }

  .notifications-label {
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #9A9A9A;

    padding-top: 20px;
  }
</style>
