<template>
  <div class="wrapper">
    <news-list @show-news="displayNews"></news-list>
    <transition name="slides">
      <news v-if="showNews" :news="currentNews" @back-to-list="showList"></news>
    </transition>
  </div>
</template>

<script>
    import NewsList from "@/components/pages/Apps/News/NewsList";
    import News from "@/components/pages/Apps/News/News";

    export default {
        name: "NewsWrapper",
        props: ['profile'],
        components: {
            newsList: NewsList,
            news: News
        },
        data() {
            return {
                showNews: false,
                currentNews: null
            }
        },
        methods: {
            showList() {
                this.showNews = false
            },
            displayNews(news) {
                this.currentNews = news
                this.showNews = true
            }
        }
    }
</script>

<style scoped>
  .wrapper {
    position: relative;
  }

  .slides-enter-active {
    transform: translateX(400px);
  }

  .slides-enter-active.slides-enter-to {
    transform: translateX(0);
    transition: transform .5s;
  }

  .slides-leave-active {
    transform: translateX(0);
  }

  .slides-leave-active.slides-leave-to {
    transform: translateX(400px);
    transition: transform .5s;
  }
</style>