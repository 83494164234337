<template>
  <div>
    <card-icon v-if="iconName === 'main'" :color="color"></card-icon>
    <student-icon v-else-if="iconName === 'other'" :color="color"></student-icon>
    <span v-if="displayName">{{ displayName }}</span>
  </div>
</template>

<script>
    import CardIcon from "@/components/icons/CardIcon";
    import StudentIcon from "@/components/icons/StudentIcon";

    export default {
        displayName: "CardMenuItem",
        props: ['iconName', 'displayName', 'color'],
        components: {
            'card-icon': CardIcon,
            'student-icon': StudentIcon
        },
    }
</script>

<style scoped>

</style>