<template>
  <div class="wrapper">
    <events-list @show-event="displayEvent"/>
    <transition name="slides">
      <event v-if="showEvent" :event="currentEvent" @back-to-list="showList"/>
    </transition>
    <transition name="slides-down">
      <event-filter v-if="showFilter" :event="currentEvent" @back-to-list="showList"></event-filter>
    </transition>
    <transition name="slides-down">
      <add-to-calendar-menu v-if="isAddToCalendarMenuShown"></add-to-calendar-menu>
    </transition>
  </div>
</template>

<script>
    import EventsList from "@/components/pages/Apps/Calendar/EventsList";
    import Event from "@/components/pages/Apps/Calendar/Event";
    import EventFilter from "./EventFilter";
    import AddToCalendarMenu from "./AddToCalendarMenu";
    import { mapGetters, mapActions } from 'vuex';
    import { IS_CALENDAR_FILTER_SHOWN } from "../../../../store/getters/ui";
    import { HIDE_CALENDAR_FILTER } from "../../../../store/actions/ui";
    import { IS_ADD_TO_CALENDAR_MENU_SHOWN } from "../../../../store/getters/ui";

    export default {
        name: "EventsWrapper",
        props: ['profile'],
        components: {
            eventsList: EventsList,
            event: Event,
            eventFilter: EventFilter,
            addToCalendarMenu: AddToCalendarMenu
        },
        data() {
            return {
                showEvent: false,
                currentEvent: null
            }
        },
        methods: {
            ...mapActions({
              hideFilter: HIDE_CALENDAR_FILTER
            }),
            showList() {
                this.showEvent = false;
                this.hideFilter();
            },
            displayEvent(event) {
                this.currentEvent = event
                this.showEvent = true
            }
        },
        computed: {
          ...mapGetters({
            showFilter: IS_CALENDAR_FILTER_SHOWN,
            isAddToCalendarMenuShown: IS_ADD_TO_CALENDAR_MENU_SHOWN
          })
        }
    }
</script>

<style scoped>
  .wrapper {
    position: relative;
  }

  .slides-enter-active {
    transform: translateX(400px);
  }

  .slides-enter-active.slides-enter-to {
    transform: translateX(0);
    transition: transform .5s;
  }

  .slides-leave-active {
    transform: translateX(0);
  }

  .slides-leave-active.slides-leave-to {
    transform: translateX(400px);
    transition: transform .5s;
  }

  /* slides down */
  .slides-down-enter-active {
    transform: translateY(100vh);
  }

  .slides-down-enter-active.slides-down-enter-to {
    transform: translateY(0);
    transition: transform .5s;
  }

  .slides-down-leave-active {
    transform: translateY(0);
  }

  .slides-down-leave-active.slides-down-leave-to {
    transform: translateY(100vh);
    transition: transform .5s;
  }

</style>