<template>
  <div>
    <apps-header heading="News"/>
    <div class="container">
      <div v-for="(item, $index) in list"
           :key="$index"
           @click="$emit('show-news',item)"
           class="news-item with-forward-arrow">
        <!--img v-if="item.enclosure.url" :src="item.enclosure.url" alt="Preview" class="preview"-->
        <img v-if="enclosureExists(item)" :src="getEnclosureUrl(item)" alt="Preview" class="preview">
        <img v-else-if="imageExists(item)" :src="getImageUrl(item)" alt="Preview" class="preview">
        <img v-else src="/images/no_image.png" alt="No image" class="preview">
        <div class="title-wrapper">
          <div class="title">{{ item.title }}</div>
          <div class="date">{{ item.pubDate.toString().trim().substr(4,18) }}</div>
        </div>
      </div>
      <infinite-loading @infinite="infiniteHandler">
        <template v-slot:no-results>
          <div class="news-list-error" v-if="error">
            {{ error }}
          </div>
          <div v-else>
            No results found
          </div>
        </template>
        <template v-slot:no-more>
          <div></div>
        </template>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
    import InfiniteLoading from 'vue-infinite-loading';
    import client from '@/client';
    import AppsHeader from "@/components/pages/Apps/AppsHeader";
    import { mapState } from 'vuex';
    import Parser from 'rss-parser';

    export default {
        name: "NewsList",
        props: ['profile'],
        components: {
            InfiniteLoading,
            appsHeader: AppsHeader
        },
        data() {
            return {
                page: 1,
                list: [],
                error: null
            }
        },
        computed: {
            ...mapState({
              url: state => state.user.profile.news_rss_url
            })
        },
        methods: {
            enclosureExists(item) {
              if (item.enclosure !== undefined) {
                return true;
              }
              return false;
            },
            getEnclosureUrl(item) {
              return item.enclosure.url;
            },
            imageExists(item) {
              if (item.image !== undefined) {
                return true;
              }
              return false;
            },
            getImageUrl(item) {
              return item.image;
            },
            infiniteHandler($state) {
              if (!this.url) {
                this.error = 'No news rss url specified. Please contact your organization';
                $state.complete();
                return;
              } else {
                try {
                  let parser = new Parser({
                      defaultRSS: 2.0,
                      customFields: {
                        item: ['image'],
                      }
                  });

                  let rssUrl = this.url;

                  const CORS_PROXY = this.$apiBaseURL + '/proxy';

                  const apiUrl = new URL(this.$apiBaseURL);

                  console.log('news url: ', this.url);

                  const fileUrl = new URL(this.url);
                  if (fileUrl.origin != apiUrl.origin) {
                    rssUrl = CORS_PROXY + '?url=' + this.url;
                  }

                  console.log('rssUrl: ', rssUrl);

                  (async () => {
                    parser.parseURL(rssUrl)
                            .then(feed => {
                              this.list.push(...feed.items);
                              $state.loaded();
                              $state.complete();

                            })
                            .catch(err => {
                              console.log(err);
                              this.error = 'Unable to parse the url specified, please contact your organization';
                              $state.complete();
                            })
                  })();
                } catch (e) {
                  console.log(e);
                  this.error = 'Unable to get news: ' + e;
                  $state.complete();
                }

              }

                /*
                  API query logic with pages count. If using, change "enclosure.url" to "image" (compatible to API data)

                  */
                // client.get('/news', {
                //     params: {
                //         page: this.page,
                //     },
                // }).then(({data}) => {
                //     if (data.error) {
                //       this.error = data.error
                //     }
                //     if (data.items[0]) {
                //         this.page += 1;
                //         this.list.push(...data.items);
                //         $state.loaded();
                //     } else {
                //         $state.complete();
                //     }
                // });
            },
        },
        mounted() {

        }
    }
</script>

<style scoped>

  .container {
    overflow-y: auto;
    height: calc(100vh - 117px);
  }

  .news-item {
    position: relative;
    padding: 5px 30px 5px 5px;
    max-height: 80px;
    margin: 5px 0;
    display: flex;

    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    box-shadow: 0 2px 10px rgba(227, 227, 227, 0.5);
    cursor: pointer;
  }

  .news-item:first-child {
    margin-top: 22px;
  }

  .news-item:last-child {
    margin-bottom: 30px;
  }

  .preview {
    height: 70px;
  }

  .title-wrapper {
    padding-left: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
  }

  .title {
    position: relative;
    font-size: 14px;
    line-height: 1.2em;
    overflow: hidden;
  }

  .title:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20%;
    height: 1.2em;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
  }

  .date {
    font-size: 14px;
    color: #AAAAAA;
  }

  .news-list-error {
    padding: 10px;
    text-align: center;
  }

  @media only screen and (min-width: 480px) {
    .container {
      height: 695px;
    }
  }
</style>
