<template>
  <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="17">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.000976562H23.2577V16.3508H0V0.000976562Z" fill="white"/>
    </mask>
    <g mask="url(#mask0)">
      <path fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.58652 0.246584C7.91422 -0.0820318 8.43022 -0.0820318 8.75791 0.246584C9.07545 0.564122 9.07545 1.09028 8.75791 1.40781L2.81606 7.34966H22.4241C22.8819 7.34966 23.2576 7.71428 23.2576 8.16843C23.2576 8.62628 22.8819 9.00105 22.4241 9.00105H2.81606L8.75791 14.9327C9.07545 15.2604 9.07545 15.7912 8.75791 16.105C8.43022 16.4327 7.91422 16.4327 7.58652 16.105L0.23791 8.75643C-0.0796288 8.43889 -0.0796288 7.91274 0.23791 7.5952L7.58652 0.246584Z"
            :fill="color"/>
    </g>
  </svg>
</template>

<script>
    export default {
        name: "ArrowBack",
        props: {
            color: {
                default: 'white'
            }
        }
    }
</script>

<style scoped>

</style>