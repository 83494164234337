import {createApp} from 'vue'
import BootstrapVue3 from 'bootstrap-vue-3'

import App from './App.vue'
import router from './router'
import store from './store'
import eventBus from './bus.js';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import '@fortawesome/fontawesome-free/css/all.css'

import './styles/AvenirNext/stylesheet.css'
import './styles/less/fonts.less'
import './styles/less/app.less'

import siteLogoUrlMap from './site_logo_url_map'
import basePathMap from './base_path_map'

const app = createApp(App)

app.config.globalProperties.$siteLogoUrl = siteLogoUrlMap[location.hostname] || 'janus_logo.png';

const environment = process.env.VUE_APP_ENVIRONMENT || 'web';
app.config.globalProperties.$environment = environment;
app.config.globalProperties.$basePath = basePathMap[environment]();
app.config.globalProperties.$apiBaseURL = process.env.VUE_APP_API_BASE_URL || 'https://api.mobileidworks.com/v1';
app.config.globalProperties.$eventBus = eventBus;

app.use(store)
app.use(router)
app.use(BootstrapVue3)
app.mount('#app')
