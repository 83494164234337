<template>
    <div class="filter">
        <div class="filter-body">
            <div class="filter-panels">
                <div class="filter-panels-group">
                    <div @click="onPanelClick('all')" class="filter-panel">
                        <i v-if="!this.filterValue || this.filterValue === 'all'" style="color: green;" class="fa fa-check"></i> All
                    </div>
<!--                    <div @click="onPanelClick('this_year')" class="filter-panel">-->
<!--                        <i v-if="this.filterValue && this.filterValue === 'this_year'" style="color: green;" class="fa fa-check"></i> This year-->
<!--                    </div>-->
                    <div @click="onPanelClick('this_month')" class="filter-panel">
                        <i v-if="this.filterValue && this.filterValue === 'this_month'" style="color: green;" class="fa fa-check"></i> This month
                    </div>
                    <div @click="onPanelClick('this_weekend')" class="filter-panel">
                        <i v-if="this.filterValue && this.filterValue === 'this_weekend'" style="color: green;" class="fa fa-check"></i>  This weekend
                    </div>
                    <div @click="onPanelClick('this_week')" class="filter-panel">
                        <i v-if="this.filterValue && this.filterValue === 'this_week'" style="color: green;" class="fa fa-check"></i> This week
                    </div>
                    <div @click="onPanelClick('today')" class="filter-panel">
                        <i v-if="this.filterValue && this.filterValue === 'today'" style="color: green;" class="fa fa-check"></i> Today
                    </div>
                </div>

                <div class="filter-panels-group filter-panels-group-last">
                    <div @click="hideEventFilter" class="filter-panel filter-panel-cancel">Cancel</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { HIDE_CALENDAR_FILTER, CHANGE_CALENDAR_FILTER_VALUE } from "../../../../store/actions/ui";
    import { mapActions, mapGetters } from 'vuex'
    import { CALENDAR_VALUE } from "../../../../store/getters/ui";

    export default {
        name: "EventFilter",
        methods: {
            ...mapActions({
                hideEventFilter: HIDE_CALENDAR_FILTER,
                changeFilterValue: CHANGE_CALENDAR_FILTER_VALUE
            }),
            onPanelClick(val) {
                this.hideEventFilter();
                this.changeFilterValue(val);
            }
        },
        computed: {
            ...mapGetters({
                filterValue: CALENDAR_VALUE
            })
        }
    }
</script>

<style lang="scss" scoped>

    .filter {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background: rgba(0, 0, 0, 0.5); // Make sure this color has an opacity of less than 1
        backdrop-filter: blur(20px);
        z-index: 20;

        &-body {
            display: flex;
            align-items: flex-end;
            height: 100%;
            overflow-y: auto;
        }

        &-panels {
            width: 100%;
            text-align: center;
            margin: 0 12px;
            margin-bottom: 10px;

            &-group {
                border-radius: 6px;
                overflow: hidden;
                &-last {
                    margin-top: 8px;
                }
            }
        }

        &-panel {
            padding: 10px;
            margin: 1px 0;
            background: #d6d6d6;
            cursor: pointer;
        }
    }

    .container h2 {
        padding-top: 10px;
        font-size: 24px;
        text-align: center;
    }

    .container iframe {
        border: none;
        width: 100%;
    }

    .container {
        overflow-y: auto;
        height: calc(100vh);
    }

    @media only screen and (min-width: 480px) {
        .container {
            height: 695px;
        }
    }
</style>