<template>
    <div style="cursor: pointer;" class="add-to-calendar" id="addToCalendar">
        <i class="fas fa-calendar-alt"></i>
    </div>
</template>

<script>
    export default {
        name: 'AddToCalendarButton'
    }
</script>