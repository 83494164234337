export const AppMenuNames = {
    APP_DIRECTORY: 'directory',
    APP_MY_COURSES: 'my-courses',
    APP_CALENDAR: 'calendar',
    APP_NEWS: 'news',
    APP_ANNOUNCEMENTS: 'announcements',
    APP_UNIVER_SERVICES: 'university-services',
    APP_CYBERSECURE: 'cybersecurity',
    APP_UNIVER_LOTTERY: 'university-housing-lottery',
    APP_TICKET_DISTR: 'ticket-distribution',
    APP_DIGITAL_MAG: 'digital-magazine',
    APP_ALUMNI_DEALS: 'alumni-deals',
    APP_HUMAN_RESOURCES: 'human-resources',
    APP_COMPLIANCE_TRAINING: 'compliance-training'
}

export const QuestionnaireResults = {
    QUESTIONNAIRE_RES_OK: 'ok',
    QUESTIONNAIRE_RES_BAD: 'bad'
}

