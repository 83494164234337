import store from '../store'
import Login from '@/components/Login'
import Main from '@/components/Main'
import Settings from "@/components/pages/Settings";
import Card from "@/components/pages/Card";
import Home from "@/components/pages/Home";
import ProfilePhoto from "@/components/parts/ProfilePhoto";
import Sos from "@/components/pages/Sos";
import Apps from "@/components/pages/Apps/Apps";
import Notifications from "@/components/pages/Notifications";
import NewsWrapper from "@/components/pages/Apps/News/NewsWrapper";
import EventsWrapper from "@/components/pages/Apps/Calendar/EventsWrapper";
import Directory from "@/components/pages/Apps/Directory";
import AnnouncementsWrapper from "../components/pages/Apps/Announcements/AnnouncementsWrapper";

import client from '@/client'

import { USER_LOG_ACTION } from "@/store/actions/user";
import {IS_ADD_TO_CALENDAR_MENU_SHOWN, IS_CALENDAR_FILTER_SHOWN} from "@/store/getters/ui";
import {HIDE_ADD_TO_CALENDAR_MENU, HIDE_CALENDAR_FILTER} from "@/store/actions/ui";

const ifNotAuthenticated = (to, from) => {
    if (!store.getters.isAuthenticated) {
        return true
    }
    return '/'
};

const ifAuthenticated = (to, from) => {
    if (store.getters.isAuthenticated) {
        return true
    }
    return '/login'
};

const handle404 = (to, from) => {
    if (store.getters.isAuthenticated) {
        return '/'
    }
    return '/login'
};

import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: '/',
        component: Main,
        beforeEnter: ifAuthenticated,
        children:
            [
                {
                    path: '',
                    component: Home,
                    children: [
                        {
                            path: '',
                            name: 'Home',
                            component: ProfilePhoto,
                        },
                        {
                            path: 'card/:name',
                            name: 'Card',
                            component: Card,
                        },
                    ]
                },
                {
                    path: '/settings',
                    name: 'Settings',
                    component: Settings,
                },
                {
                    path: '/apps',
                    name: 'Apps',
                    component: Apps,
                },
                {
                    path: '/apps/news',
                    name: 'News',
                    component: NewsWrapper
                },
                {
                    path: '/apps/announcements',
                    name: 'Announcements',
                    component: AnnouncementsWrapper
                },
                {
                    path: '/apps/calendar',
                    name: 'Calendar',
                    component: EventsWrapper
                },
                {
                    path: '/apps/directory',
                    name: 'Directory',
                    component: Directory
                },
                {
                    path: '/notifications',
                    name: 'Notifications',
                    component: Notifications,
                },
                {
                    path: 'sos',
                    name: 'Sos',
                    component: Sos,
                }
            ]
    },
    {
        path: '/:pathMatch(.*)*',
        beforeEnter: handle404
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})



router.beforeEach((to, from) => {
    if (store.state.user.profile && store.state.auth.token) {
        setTimeout(() => {
            store.dispatch(USER_LOG_ACTION, to.fullPath);
        }, 500);
    }

    if (store.getters[IS_ADD_TO_CALENDAR_MENU_SHOWN]) {
        store.dispatch(HIDE_ADD_TO_CALENDAR_MENU)
        return false
    } else if (store.getters[IS_CALENDAR_FILTER_SHOWN]) {
        store.dispatch(HIDE_CALENDAR_FILTER)
        return false
    } else {
        return true
    }
});


export default router;

