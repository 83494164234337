<template>
  <div>
    <svg v-if="color === 'blue'" width="30"
         height="22"
         viewBox="0 0 30 22"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M9.96094 8.20312C9.96094 7.3954 9.30382 6.73828 8.49609 6.73828C7.68837 6.73828 7.03125 7.3954 7.03125 8.20312C7.03125 9.01085 7.68837 9.66797 8.49609 9.66797C9.30382 9.66797 9.96094 9.01085 9.96094 8.20312Z"
            fill="white"/>
      <path d="M9.96094 8.20312C9.96094 7.3954 9.30382 6.73828 8.49609 6.73828C7.68837 6.73828 7.03125 7.3954 7.03125 8.20312C7.03125 9.01085 7.68837 9.66797 8.49609 9.66797C9.30382 9.66797 9.96094 9.01085 9.96094 8.20312Z"
            fill="url(#paint0_linear)"/>
      <path d="M8.49609 11.4258C6.8177 11.4258 5.43503 12.7153 5.28671 14.3555H11.7055C11.5572 12.7153 10.1745 11.4258 8.49609 11.4258Z"
            fill="white"/>
      <path d="M8.49609 11.4258C6.8177 11.4258 5.43503 12.7153 5.28671 14.3555H11.7055C11.5572 12.7153 10.1745 11.4258 8.49609 11.4258Z"
            fill="url(#paint1_linear)"/>
      <path d="M27.3633 0H2.63672C1.18286 0 0 1.18286 0 2.63672V18.457C0 19.9109 1.18286 21.0938 2.63672 21.0938H27.3633C28.8171 21.0938 30 19.9109 30 18.457V2.63672C30 1.18286 28.8171 0 27.3633 0ZM16.2305 6.15234H19.7461C20.2316 6.15234 20.625 6.54579 20.625 7.03125C20.625 7.51671 20.2316 7.91016 19.7461 7.91016H16.2305C15.745 7.91016 15.3516 7.51671 15.3516 7.03125C15.3516 6.54579 15.745 6.15234 16.2305 6.15234ZM13.4766 15.2344C13.4766 15.7198 13.0831 16.1133 12.5977 16.1133H4.39453C3.90907 16.1133 3.51562 15.7198 3.51562 15.2344V14.6484C3.51562 12.7874 4.54239 11.1623 6.0585 10.3077C5.57007 9.74258 5.27344 9.00696 5.27344 8.20312C5.27344 6.42609 6.71906 4.98047 8.49609 4.98047C10.2731 4.98047 11.7188 6.42609 11.7188 8.20312C11.7188 9.00696 11.4221 9.74258 10.9337 10.3077C12.4498 11.1623 13.4766 12.7874 13.4766 14.6484V15.2344ZM25.6055 14.9414H16.2305C15.745 14.9414 15.3516 14.548 15.3516 14.0625C15.3516 13.577 15.745 13.1836 16.2305 13.1836H25.6055C26.0909 13.1836 26.4844 13.577 26.4844 14.0625C26.4844 14.548 26.0909 14.9414 25.6055 14.9414ZM25.6055 11.4258H16.2305C15.745 11.4258 15.3516 11.0323 15.3516 10.5469C15.3516 10.0614 15.745 9.66797 16.2305 9.66797H25.6055C26.0909 9.66797 26.4844 10.0614 26.4844 10.5469C26.4844 11.0323 26.0909 11.4258 25.6055 11.4258Z"
            fill="white"/>
      <path d="M27.3633 0H2.63672C1.18286 0 0 1.18286 0 2.63672V18.457C0 19.9109 1.18286 21.0938 2.63672 21.0938H27.3633C28.8171 21.0938 30 19.9109 30 18.457V2.63672C30 1.18286 28.8171 0 27.3633 0ZM16.2305 6.15234H19.7461C20.2316 6.15234 20.625 6.54579 20.625 7.03125C20.625 7.51671 20.2316 7.91016 19.7461 7.91016H16.2305C15.745 7.91016 15.3516 7.51671 15.3516 7.03125C15.3516 6.54579 15.745 6.15234 16.2305 6.15234ZM13.4766 15.2344C13.4766 15.7198 13.0831 16.1133 12.5977 16.1133H4.39453C3.90907 16.1133 3.51562 15.7198 3.51562 15.2344V14.6484C3.51562 12.7874 4.54239 11.1623 6.0585 10.3077C5.57007 9.74258 5.27344 9.00696 5.27344 8.20312C5.27344 6.42609 6.71906 4.98047 8.49609 4.98047C10.2731 4.98047 11.7188 6.42609 11.7188 8.20312C11.7188 9.00696 11.4221 9.74258 10.9337 10.3077C12.4498 11.1623 13.4766 12.7874 13.4766 14.6484V15.2344ZM25.6055 14.9414H16.2305C15.745 14.9414 15.3516 14.548 15.3516 14.0625C15.3516 13.577 15.745 13.1836 16.2305 13.1836H25.6055C26.0909 13.1836 26.4844 13.577 26.4844 14.0625C26.4844 14.548 26.0909 14.9414 25.6055 14.9414ZM25.6055 11.4258H16.2305C15.745 11.4258 15.3516 11.0323 15.3516 10.5469C15.3516 10.0614 15.745 9.66797 16.2305 9.66797H25.6055C26.0909 9.66797 26.4844 10.0614 26.4844 10.5469C26.4844 11.0323 26.0909 11.4258 25.6055 11.4258Z"
            fill="url(#paint2_linear)"/>
      <defs>
        <linearGradient id="paint0_linear"
                        x1="-8.57139"
                        y1="-2.63673"
                        x2="18.0194"
                        y2="36.6739"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#3DABFE"/>
          <stop offset="1" stop-color="#4F83F9"/>
        </linearGradient>
        <linearGradient id="paint1_linear"
                        x1="-8.57139"
                        y1="-2.63673"
                        x2="18.0194"
                        y2="36.6739"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#3DABFE"/>
          <stop offset="1" stop-color="#4F83F9"/>
        </linearGradient>
        <linearGradient id="paint2_linear"
                        x1="-8.57139"
                        y1="-2.63673"
                        x2="18.0194"
                        y2="36.6739"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#3DABFE"/>
          <stop offset="1" stop-color="#4F83F9"/>
        </linearGradient>
      </defs>
    </svg>
    <svg v-if="color === 'gray'" width="30"
         height="22"
         viewBox="0 0 30 22"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M9.96094 8.20312C9.96094 7.3954 9.30382 6.73828 8.49609 6.73828C7.68837 6.73828 7.03125 7.3954 7.03125 8.20312C7.03125 9.01085 7.68837 9.66797 8.49609 9.66797C9.30382 9.66797 9.96094 9.01085 9.96094 8.20312Z"
            fill="#8E8E93"/>
      <path d="M8.49609 11.4258C6.8177 11.4258 5.43503 12.7153 5.28671 14.3555H11.7055C11.5572 12.7153 10.1745 11.4258 8.49609 11.4258Z"
            fill="#8E8E93"/>
      <path d="M27.3633 0H2.63672C1.18286 0 0 1.18286 0 2.63672V18.457C0 19.9109 1.18286 21.0938 2.63672 21.0938H27.3633C28.8171 21.0938 30 19.9109 30 18.457V2.63672C30 1.18286 28.8171 0 27.3633 0ZM16.2305 6.15234H19.7461C20.2316 6.15234 20.625 6.54579 20.625 7.03125C20.625 7.51671 20.2316 7.91016 19.7461 7.91016H16.2305C15.745 7.91016 15.3516 7.51671 15.3516 7.03125C15.3516 6.54579 15.745 6.15234 16.2305 6.15234ZM13.4766 15.2344C13.4766 15.7198 13.0831 16.1133 12.5977 16.1133H4.39453C3.90907 16.1133 3.51562 15.7198 3.51562 15.2344V14.6484C3.51562 12.7874 4.54239 11.1623 6.0585 10.3077C5.57007 9.74258 5.27344 9.00696 5.27344 8.20312C5.27344 6.42609 6.71906 4.98047 8.49609 4.98047C10.2731 4.98047 11.7188 6.42609 11.7188 8.20312C11.7188 9.00696 11.4221 9.74258 10.9337 10.3077C12.4498 11.1623 13.4766 12.7874 13.4766 14.6484V15.2344ZM25.6055 14.9414H16.2305C15.745 14.9414 15.3516 14.548 15.3516 14.0625C15.3516 13.577 15.745 13.1836 16.2305 13.1836H25.6055C26.0909 13.1836 26.4844 13.577 26.4844 14.0625C26.4844 14.548 26.0909 14.9414 25.6055 14.9414ZM25.6055 11.4258H16.2305C15.745 11.4258 15.3516 11.0323 15.3516 10.5469C15.3516 10.0614 15.745 9.66797 16.2305 9.66797H25.6055C26.0909 9.66797 26.4844 10.0614 26.4844 10.5469C26.4844 11.0323 26.0909 11.4258 25.6055 11.4258Z"
            fill="#8E8E93"/>
    </svg>
    <svg v-if="color === 'white'" width="30"
         height="22"
         viewBox="0 0 30 22"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M9.96094 8.20312C9.96094 7.3954 9.30382 6.73828 8.49609 6.73828C7.68837 6.73828 7.03125 7.3954 7.03125 8.20312C7.03125 9.01085 7.68837 9.66797 8.49609 9.66797C9.30382 9.66797 9.96094 9.01085 9.96094 8.20312Z"
            fill="#FFFFFF"/>
      <path d="M8.49609 11.4258C6.8177 11.4258 5.43503 12.7153 5.28671 14.3555H11.7055C11.5572 12.7153 10.1745 11.4258 8.49609 11.4258Z"
            fill="#FFFFFF"/>
      <path d="M27.3633 0H2.63672C1.18286 0 0 1.18286 0 2.63672V18.457C0 19.9109 1.18286 21.0938 2.63672 21.0938H27.3633C28.8171 21.0938 30 19.9109 30 18.457V2.63672C30 1.18286 28.8171 0 27.3633 0ZM16.2305 6.15234H19.7461C20.2316 6.15234 20.625 6.54579 20.625 7.03125C20.625 7.51671 20.2316 7.91016 19.7461 7.91016H16.2305C15.745 7.91016 15.3516 7.51671 15.3516 7.03125C15.3516 6.54579 15.745 6.15234 16.2305 6.15234ZM13.4766 15.2344C13.4766 15.7198 13.0831 16.1133 12.5977 16.1133H4.39453C3.90907 16.1133 3.51562 15.7198 3.51562 15.2344V14.6484C3.51562 12.7874 4.54239 11.1623 6.0585 10.3077C5.57007 9.74258 5.27344 9.00696 5.27344 8.20312C5.27344 6.42609 6.71906 4.98047 8.49609 4.98047C10.2731 4.98047 11.7188 6.42609 11.7188 8.20312C11.7188 9.00696 11.4221 9.74258 10.9337 10.3077C12.4498 11.1623 13.4766 12.7874 13.4766 14.6484V15.2344ZM25.6055 14.9414H16.2305C15.745 14.9414 15.3516 14.548 15.3516 14.0625C15.3516 13.577 15.745 13.1836 16.2305 13.1836H25.6055C26.0909 13.1836 26.4844 13.577 26.4844 14.0625C26.4844 14.548 26.0909 14.9414 25.6055 14.9414ZM25.6055 11.4258H16.2305C15.745 11.4258 15.3516 11.0323 15.3516 10.5469C15.3516 10.0614 15.745 9.66797 16.2305 9.66797H25.6055C26.0909 9.66797 26.4844 10.0614 26.4844 10.5469C26.4844 11.0323 26.0909 11.4258 25.6055 11.4258Z"
            fill="#FFFFFF"/>
    </svg>
  </div>
</template>

<script>
    export default {
        name: 'CardIcon',
        props: ['color']
    }
</script>