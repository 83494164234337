import {AUTH_ERROR, AUTH_LOGOUT, AUTH_REQUEST, AUTH_REQUEST_LDAP, AUTH_SUCCESS} from '../actions/auth'
import {USER_REQUEST} from '../actions/user'
import client from '@/client'
import {NOTIFICATIONS_INTERVAL_CLEAR} from "../actions/notifications";

const state = { token: localStorage.getItem('user-token') || '', status: '', hasLoadedOnce: false };

const getters = {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status,
};

const actions = {
    [AUTH_REQUEST_LDAP]: ({commit, dispatch}, payload) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_REQUEST_LDAP);
            client.post('/ldap', {
                username: payload.username,
                password: payload.password
            })
                .then(response => {
                    const token = response.data.token;
                    if (!token) {
                        const err = new Error('No token supplied');
                        commit(AUTH_ERROR, err);
                        localStorage.removeItem('user-token');
                        reject(err);
                    } else {
                        localStorage.setItem('user-token', token);
                        client.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                        commit(AUTH_SUCCESS, response.data);
                        dispatch(USER_REQUEST).then(() => resolve(response.data));
                    }
                })
                .catch(err => {
                    commit(AUTH_ERROR, err);
                    localStorage.removeItem('user-token');
                    reject(err);
                })
        })
    },
    [AUTH_REQUEST]: ({commit, dispatch}, externalId) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_REQUEST)
            client.get('/login?external_id=' + externalId)
                .then(resp => {
                    const token = resp.data.token;
                    if (!token) {
                        const err = new Error('No token supplied');
                        commit(AUTH_ERROR, err)
                        localStorage.removeItem('user-token')
                        reject(err)
                    } else {
                        localStorage.setItem('user-token', token)
                        client.defaults.headers.common['Authorization'] = 'Bearer ' + token
                        commit(AUTH_SUCCESS, resp.data)
                        dispatch(USER_REQUEST).then(() => resolve(resp.data))
                    }
                })
                .catch(err => {
                    commit(AUTH_ERROR, err)
                    localStorage.removeItem('user-token')
                    reject(err)
                })
        })
    },
    [AUTH_LOGOUT]: ({commit, dispatch}) => {
        return new Promise((resolve) => {
            commit(AUTH_LOGOUT)
            dispatch(NOTIFICATIONS_INTERVAL_CLEAR)
            localStorage.removeItem('user-token')
            localStorage.removeItem('profile')
            resolve()
        })
    }
};

const mutations = {
    [AUTH_REQUEST]: (state) => {
        state.status = 'loading'
    },
    [AUTH_REQUEST_LDAP]: (state) => {
        state.status = 'loading'
    },
    [AUTH_SUCCESS]: (state, resp) => {
        state.status = 'success'
        state.token = resp.token
        state.hasLoadedOnce = true
    },
    [AUTH_ERROR]: (state) => {
        state.status = 'error'
        state.hasLoadedOnce = true
    },
    [AUTH_LOGOUT]: (state) => {
        state.token = ''
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
}
