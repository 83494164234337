<template>
<!--    <div :style="`height: ${height || '100'}px; width: ${height || '100'}px;`" class="icon">-->
<!--        <div :style="`background: ${color || 'white'};`" class="icon-bar__top"></div>-->
<!--        <div :style="`background: ${color || 'white'};`" class="icon-bar__middle"></div>-->
<!--        <div :style="`background: ${color || 'white'};`" class="icon-bar__bottom"></div>-->
<!--    </div>-->
    <div style="cursor: pointer;">
        <i class="fas fa-calendar"></i>
    </div>
</template>

<script>
    export default {
        name: "FilterIcon",
        props: ['height', 'color']
    }
</script>

<style lang="scss" scoped>
    .icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .icon div {
        height: 2px;
        margin: 2px 0;
    }

    .icon-bar__top {
        width: 80%;
    }
    .icon-bar__middle {
        width: 60%;
    }
    .icon-bar__bottom {
        width: 40%;
    }
</style>