<template>
  <div>
    <apps-header heading="Directory"/>
    <div id="directoryContainer" class="container">
      <div class="buttons-wrapper">
        <button
            v-for="(letter, key) in alphabet"
            :key="key"
            :id="letter"
            @click="sendDirectorySearchRequestByLetter(letter)"
            :class="{'active-letter': letter === activeLetter}"
        >
          {{ letter }}
        </button>
      </div>
      <div class="filter">
        <div>
          <label for="">Department</label>
          <v-select
              label="label"
              :reduce="item => item.id"
              :options="departments"
              @input="onDirectoryInput"
              v-model="filterParams.department"
          >
            <template #open-indicator="{ attributes }">
              <div class="select__open-indicator" v-bind="attributes">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 292.362 292.362"><path d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z"></path></svg>
              </div>
            </template>
          </v-select>

        </div>
        <div>
          <label for="firstName">First name:</label>
          <input v-model="filterParams.first_name" type="text" class="form-control" id="firstName" placeholder="">
        </div>
        <div>
          <label for="lastName">Last name:</label>
          <input v-model="filterParams.last_name" type="text" class="form-control" id="lastName" placeholder="">
        </div>
        <div>
          <button @click.prevent="sendDirectorySearchRequest" class="btn btn-primary search" id="search" type="submit">Search</button>
        </div>
      </div>
      <div style="display: flex; justify-content: center;">
        <preloader :show="requestsProcessing.searchContacts"></preloader>
      </div>
      <transition name="fade">
        <div v-if="!requestsProcessing.searchContacts" class="search-results">

          <div v-if="shownDepartment" style="border: 1px solid #ffd34c;" class="card-body department-card">
            <div class="list-group">
              <li class="list-group-item" style="background-color:#fbfbfb;">
                <i style="color: #ffd34c;" class="fas fa-award"/>
                <span style="padding-left:10px">
                  <a target="_parent">
                    <b>{{ this.shownDepartment.dept_name }}</b>
                  </a>
                </span>
              </li>
              <li class="list-group-item">
                <i style="color: #ffd34c;" class="fas fa-building"/>
                <span style="padding-left:10px">{{ this.shownDepartment.building_name }} {{ this.shownDepartment.room_number }}</span>
              </li>
              <li class="list-group-item">
                <i style="color: #ffd34c;" class="fas fa-phone"/>
                <span style="padding-left:10px">
                  <a :href="'tel:' + shownDepartment.dept_phone">
                    {{ this.shownDepartment.dept_phone }}
                  </a></span>
              </li>
            </div>
          </div>

          <div v-for="(item, index) in contactsFiltered" :key="index" class="card-body staff-card">
            <ul class="list-group">
              <li class="list-group-item" style="background-color:#fbfbfb;">
                <i style="color: #ffd34c;" class="fas fa-user"/>
                <span style="padding-left:10px">
                  <a target="_parent">
                    <b>{{ item.last_name }}, {{ item.first_name}}</b>
                  </a>
                </span>
              </li>
              <li class="list-group-item">
                <i style="color: #ffd34c;" class="fas fa-award"/>
                <span style="padding-left:10px">
                  {{ item.job_title }}
                </span>
              </li>
              <li class="list-group-item">
                <i style="color: #ffd34c;" class="fas fa-envelope"/>
                <span style="padding-left:10px">
                  <a :href="'mailto:' + item.work_email">
                    {{ item.work_email }}
                  </a>
                </span>
              </li>
              <li class="list-group-item">
                <i style="color: #ffd34c;" class="fas fa-phone"/>
                <span style="padding-left:10px">
                  <a :href="'tel:' + item.work_phone">
                    {{ item.work_phone }}
                  </a></span>
              </li>
              <li class="list-group-item"><i style="color: #ffd34c;" class="fas fa-building"/><span
                  style="padding-left:10px">{{ item.room_number }}, {{ item.building_name}}</span>
              </li>
              <li class="list-group-item"><i style="color: #ffd34c;" class="fas fa-circle"/><span
                  style="padding-left:10px">{{ item.department.dept_name }}</span>
              </li>
            </ul>
            <div class="d-flex justify-content-between align-items-center"></div>
          </div>
          <div class="directory-error" v-if="error">
            {{ error }}
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
    import AppsHeader from "@/components/pages/Apps/AppsHeader";
    import client from '@/client';
    import { mapState } from 'vuex';

    import PreloaderLocal from "../../PreloaderLocal";

    // xml
    import { parseXml, xml2json } from "@/xml";

    import vSelect from 'vue-select'
    import 'vue-select/dist/vue-select.css'
    import FuzzySet from 'fuzzyset'

    export default {
        name: "Directory",
        props: ['profile'],
        components: {
            appsHeader: AppsHeader,
            vSelect,
            preloader: PreloaderLocal
        },
        data() {
            return {
                alphabet: ['A', 'B', 'C', 'D', 'E',
                    'F', 'G', 'H', 'I', 'J',
                    'K', 'L', 'M', 'N', 'O',
                    'P', 'Q', 'R', 'S', 'T',
                    'U', 'V', 'W', 'X', 'Y', 'Z'],
                activeLetter: '',
                showHardcoded: false,
                showResults: true,
                showDepartmentInfo: false,
                departments: [],
                shownDepartment: null,
                filterParams: {
                    first_name: null,
                    last_name: null,
                    departments: [],
                    department: null
                },
                contacts: [],
                contactsFiltered: [],
                requestsProcessing: {
                  departments: false,
                  searchContacts: false
                },
                error: null
            }
        },
      watch: {
          url: {
            immediate: true,
            handler(val) {
              if (val) {
                this.setup()
              }
            },
          },
      },
        computed: {
          ...mapState({
            url: state => state.user.profile.directory_url
          }),
          selectedDepartment() {
            if (!this.filterParams.department) return null;

            const filtered = this.departments.filter(item => {
              return [this.filterParams.department].includes(item['@id']);
            });

            return filtered.length ? filtered[0] : null;
          }
        },
        methods: {
          onDirectoryInput(val) {
            if (!val) {
              this.shownDepartment = null;
            }
          },
          sendDirectorySearchRequest() {
            this.requestsProcessing.searchContacts = true;

            // copy the source value
            let contactsFiltered = this.contacts.filter(item => true);
            this.activeLetter = '';

            if (this.filterParams.departments.length) {
              contactsFiltered = contactsFiltered.filter(item => {
                return this.filterParams.departments.includes(item.department['@id'])
              });
            }

            if (this.filterParams.department) {
              contactsFiltered = contactsFiltered.filter(item => {
                let departments = [this.filterParams.department];
                return departments.includes(item.department['@id']);
              });

              this.shownDepartment = this.selectedDepartment;
            }

            if (this.filterParams.first_name) {

              const namesArray = contactsFiltered.map(item => {
                return item.first_name.toLowerCase();
              });
              const fs = FuzzySet(namesArray);

              const mapByName = {};

              contactsFiltered.forEach(item => {
                if (!mapByName[item.first_name.toLowerCase()]) {
                  mapByName[item.first_name.toLowerCase()] = [];
                }
                mapByName[item.first_name.toLowerCase()].push(item);
              });

              const search_name = this.filterParams.first_name.toLowerCase();

              const result = fs.get(search_name);

              contactsFiltered = [];
              if (result) {
                result.forEach(resultItem => {
                  const key = resultItem[1];
                  mapByName[key].forEach(item => {
                    contactsFiltered.push(item);
                  });
                })
              }

            }

            if (this.filterParams.last_name) {

              const namesArray = contactsFiltered.map(item => {
                return item.last_name.toLowerCase();
              });
              const fs = FuzzySet(namesArray);

              const mapByName = {};

              contactsFiltered.forEach(item => {
                if (!mapByName[item.last_name.toLowerCase()]) {
                  mapByName[item.last_name.toLowerCase()] = [];
                }
                mapByName[item.last_name.toLowerCase()].push(item);
              });

              const search_name = this.filterParams.last_name.toLowerCase();

              const result = fs.get(search_name);

              contactsFiltered = [];
              if (result) {
                result.forEach(resultItem => {
                  const key = resultItem[1];
                  mapByName[key].forEach(item => {
                    contactsFiltered.push(item);
                  });
                })
              }

            }

            this.contactsFiltered = contactsFiltered;
            this.requestsProcessing.searchContacts = false;
          },
          sendDirectorySearchRequestByLetter(letter) {
            this.requestsProcessing.searchContacts = true;

            if (this.activeLetter === letter) {
              this.activeLetter = '';
              this.contactsFiltered = [];
            } else {
              this.activeLetter = letter;
              this.contactsFiltered = this.contacts.filter(item => {
                const lastName = item.last_name.toLowerCase();
                letter = letter.toLowerCase();

                return letter == lastName[0];
              });
            }

            this.requestsProcessing.searchContacts = false;
          },
          setup() {
            let directoryUrl = this.url;
            let fileUrl = new URL(this.url);
            let apiBaseUrl = new URL(this.$apiBaseURL);
            const CORS_PROXY = this.$apiBaseURL + '/proxy';

            if (fileUrl.origin != apiBaseUrl.origin) {
              directoryUrl = CORS_PROXY + '?url=' + this.url;
            }

            this.requestsProcessing.searchContacts = true;
            client.get(directoryUrl)
              .then(response => {
                try {
                  let xml = response.data;
                  let dom = parseXml(xml);
                  let result = xml2json(dom);
                  result = result.replace('undefined', '');
                  result = result.replace('&amp;', '&');

                  let directory = JSON.parse(result);

                  directory.departments.department.forEach((dept, i) => {
                    dept.id = dept['@id'];
                    dept.label = dept.dept_name;
                    this.departments.push(dept);

                    if (Array.isArray(dept.employees.employee)) {
                      dept.employees.employee.forEach((employee, i) => {
                        employee.department = dept;
                        employee.department.employees.employee = [];
                        this.contacts.push(employee);
                      })
                    } else {
                      const employee = dept.employees.employee;
                      employee.department = dept;
                      employee.department.employees.employee = [];
                      this.contacts.push(employee);
                    }

                    // const showAllCallback = item => true;
                    const hideAllCallback = item => false;
                    this.contactsFiltered = this.contacts.filter(hideAllCallback);
                  });
                  this.departments.unshift({
                    id: 0,
                    label: 'All'
                  });
                  this.filterParams.department = 0;

                  console.log('directory done');
                  console.log(directory);
                }
                catch (e) {
                  console.log('error parsing directory file');
                  console.log(e);
                  this.requestsProcessing.searchContacts = false;

                  this.contacts = [];
                  this.contactsFiltered = [];
                  this.departments = [];

                  this.error = 'Unable to parse directory structure. Invalid XML format. Please contact your organization';
                }
              })
              .catch(err => {
                console.log(err);
                this.error = 'Unable to parse directory structure. Please contact your organization';
              })
              .finally(() => {
                this.requestsProcessing.searchContacts = false;
              })
          },
          adjustContainerHeight() {
            const app = document.getElementById('app');
            const directoryContainer = document.getElementById('directoryContainer');
            if (app && directoryContainer) {
              directoryContainer.style.height = app.clientHeight + 'px';
            }
          },
          addSearchOnEnterClick() {
            document.addEventListener('keydown', e => {
              if (e.keyCode === 13) {
                this.sendDirectorySearchRequest();
              }
            })
          }
        },
        mounted(){
          this.adjustContainerHeight();
          this.addSearchOnEnterClick();
        }
    }
</script>

<style scoped>

  .container {
    overflow-y: auto;
    height: calc(100vh - 117px);
  }

  .buttons-wrapper {
    padding-top: 10px;
    text-align: center;
  }

  .buttons-wrapper button {
    border: 0;
    background-color: #fff;
    border-bottom: 2px solid #999;
    cursor: pointer;
    margin: 0 2px;
    transition: border-bottom-color .3s ease;
  }

  .buttons-wrapper button.active-letter {
    border-bottom-color: #ffd34c;
  }

  .search {
    margin-top: 15px;
  }

  .search-results {
    padding-bottom: 80px;
  }

  .directory-error {
    padding: 10px;
    text-align: center;
  }

  .vue-treeselect__menu {
    line-height: 300% !important;
  }

  .table thead th {
    vertical-align: middle !important;
  }

  .table tbody td {
    vertical-align: middle !important;
  }

  .card-body {
    padding: 1.25rem 0;
  }

  .department-card {
    padding: 0 !important;
    margin: 1.25rem 0;
    border-radius: 6px;
    border: 1px solid #ffd34c;
  }
  .vs__open-indicator {
    width: 9px;
    fill: #ccc;
    margin-right: 4px;
    cursor: pointer;
    transition: fill .3s ease;
  }
  .vs__open-indicator:hover {
    fill: #616161;
  }
  :deep(.vs__clear) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 348.333 348.333'%3E%3Cpath fill='%23ccc' d='M336.559 68.611L231.016 174.165l105.543 105.549c15.699 15.705 15.699 41.145 0 56.85-7.844 7.844-18.128 11.769-28.407 11.769-10.296 0-20.581-3.919-28.419-11.769L174.167 231.003 68.609 336.563c-7.843 7.844-18.128 11.769-28.416 11.769-10.285 0-20.563-3.919-28.413-11.769-15.699-15.698-15.699-41.139 0-56.85l105.54-105.549L11.774 68.611c-15.699-15.699-15.699-41.145 0-56.844 15.696-15.687 41.127-15.687 56.829 0l105.563 105.554L279.721 11.767c15.705-15.687 41.139-15.687 56.832 0 15.705 15.699 15.705 41.145.006 56.844z'%3E%3C/path%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    width: 15px;
    height: 100%;
    background-size: 60%;
    transition: all .3s ease;
  }
  :deep(.vs__clear:hover) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 348.333 348.333'%3E%3Cpath fill='red' d='M336.559 68.611L231.016 174.165l105.543 105.549c15.699 15.705 15.699 41.145 0 56.85-7.844 7.844-18.128 11.769-28.407 11.769-10.296 0-20.581-3.919-28.419-11.769L174.167 231.003 68.609 336.563c-7.843 7.844-18.128 11.769-28.416 11.769-10.285 0-20.563-3.919-28.413-11.769-15.699-15.698-15.699-41.139 0-56.85l105.54-105.549L11.774 68.611c-15.699-15.699-15.699-41.145 0-56.844 15.696-15.687 41.127-15.687 56.829 0l105.563 105.554L279.721 11.767c15.705-15.687 41.139-15.687 56.832 0 15.705 15.699 15.705 41.145.006 56.844z'%3E%3C/path%3E%3C/svg%3E");
  }
  :deep(.vs__clear svg) {
    display: none;
  }
</style>

