<template>
  <div>
    <svg v-if="color === 'blue'"
         width="28"
         height="28"
         viewBox="0 0 28 28"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M14 0C11.2859 0 9.07812 2.26248 9.07812 4.97656C9.07812 7.69065 11.2859 9.89844 14 9.89844C16.7141 9.89844 18.9219 7.69065 18.9219 4.97656C18.9219 2.26248 16.7141 0 14 0Z"
            fill="black"/>
      <path d="M14 0C11.2859 0 9.07812 2.26248 9.07812 4.97656C9.07812 7.69065 11.2859 9.89844 14 9.89844C16.7141 9.89844 18.9219 7.69065 18.9219 4.97656C18.9219 2.26248 16.7141 0 14 0Z"
            fill="url(#paint0_linear)"/>
      <path d="M15.6406 9.89844H14H12.3594C11.2023 9.89844 10.0842 10.1975 9.06774 10.7063C10.7942 11.1722 12.4197 11.8431 14 12.7501C15.5804 11.8431 17.2058 11.1722 18.9323 10.7063C17.9158 10.1975 16.7977 9.89844 15.6406 9.89844Z"
            fill="black"/>
      <path d="M15.6406 9.89844H14H12.3594C11.2023 9.89844 10.0842 10.1975 9.06774 10.7063C10.7942 11.1722 12.4197 11.8431 14 12.7501C15.5804 11.8431 17.2058 11.1722 18.9323 10.7063C17.9158 10.1975 16.7977 9.89844 15.6406 9.89844Z"
            fill="url(#paint1_linear)"/>
      <path d="M26.3594 14.8203C25.4533 14.8203 24.7188 15.5548 24.7188 16.4609V19.7422C24.7188 20.6482 25.4533 21.3828 26.3594 21.3828C27.2655 21.3828 28 20.6482 28 19.7422V16.4609C28 15.5548 27.2655 14.8203 26.3594 14.8203Z"
            fill="black"/>
      <path d="M26.3594 14.8203C25.4533 14.8203 24.7188 15.5548 24.7188 16.4609V19.7422C24.7188 20.6482 25.4533 21.3828 26.3594 21.3828C27.2655 21.3828 28 20.6482 28 19.7422V16.4609C28 15.5548 27.2655 14.8203 26.3594 14.8203Z"
            fill="url(#paint2_linear)"/>
      <path d="M1.64062 14.8203C0.734508 14.8203 0 15.5548 0 16.4609V19.7422C0 20.6482 0.734508 21.3828 1.64062 21.3828C2.54674 21.3828 3.28125 20.6482 3.28125 19.7422V16.4609C3.28125 15.5548 2.54674 14.8203 1.64062 14.8203Z"
            fill="black"/>
      <path d="M1.64062 14.8203C0.734508 14.8203 0 15.5548 0 16.4609V19.7422C0 20.6482 0.734508 21.3828 1.64062 21.3828C2.54674 21.3828 3.28125 20.6482 3.28125 19.7422V16.4609C3.28125 15.5548 2.54674 14.8203 1.64062 14.8203Z"
            fill="url(#paint3_linear)"/>
      <path d="M2.46094 11.5391C2.00752 11.5391 1.64062 11.906 1.64062 12.3594V13.1797C3.45029 13.1797 4.92188 14.6513 4.92188 16.4609V19.7422C4.92188 21.5519 3.45029 23.0234 1.64062 23.0234V23.8438C1.64062 24.2972 2.00752 24.6641 2.46094 24.6641C6.66646 24.6641 10.0291 25.9922 13.1797 28V14.1965C10.0149 12.382 6.61188 11.5391 2.46094 11.5391Z"
            fill="black"/>
      <path d="M2.46094 11.5391C2.00752 11.5391 1.64062 11.906 1.64062 12.3594V13.1797C3.45029 13.1797 4.92188 14.6513 4.92188 16.4609V19.7422C4.92188 21.5519 3.45029 23.0234 1.64062 23.0234V23.8438C1.64062 24.2972 2.00752 24.6641 2.46094 24.6641C6.66646 24.6641 10.0291 25.9922 13.1797 28V14.1965C10.0149 12.382 6.61188 11.5391 2.46094 11.5391Z"
            fill="url(#paint4_linear)"/>
      <path d="M26.3594 13.1797V12.3594C26.3594 11.906 25.9925 11.5391 25.5391 11.5391C21.3881 11.5391 17.9851 12.382 14.8203 14.1965V28C17.9709 25.9922 21.3335 24.6641 25.5391 24.6641C25.9925 24.6641 26.3594 24.2972 26.3594 23.8438V23.0234C24.5497 23.0234 23.0781 21.5519 23.0781 19.7422V16.4609C23.0781 14.6513 24.5497 13.1797 26.3594 13.1797Z"
            fill="black"/>
      <path d="M26.3594 13.1797V12.3594C26.3594 11.906 25.9925 11.5391 25.5391 11.5391C21.3881 11.5391 17.9851 12.382 14.8203 14.1965V28C17.9709 25.9922 21.3335 24.6641 25.5391 24.6641C25.9925 24.6641 26.3594 24.2972 26.3594 23.8438V23.0234C24.5497 23.0234 23.0781 21.5519 23.0781 19.7422V16.4609C23.0781 14.6513 24.5497 13.1797 26.3594 13.1797Z"
            fill="url(#paint5_linear)"/>
      <defs>
        <linearGradient id="paint0_linear" x1="14" y1="0" x2="14" y2="28" gradientUnits="userSpaceOnUse">
          <stop stop-color="#3DABFE"/>
          <stop offset="1" stop-color="#4F83F9"/>
        </linearGradient>
        <linearGradient id="paint1_linear" x1="14" y1="0" x2="14" y2="28" gradientUnits="userSpaceOnUse">
          <stop stop-color="#3DABFE"/>
          <stop offset="1" stop-color="#4F83F9"/>
        </linearGradient>
        <linearGradient id="paint2_linear" x1="14" y1="0" x2="14" y2="28" gradientUnits="userSpaceOnUse">
          <stop stop-color="#3DABFE"/>
          <stop offset="1" stop-color="#4F83F9"/>
        </linearGradient>
        <linearGradient id="paint3_linear" x1="14" y1="0" x2="14" y2="28" gradientUnits="userSpaceOnUse">
          <stop stop-color="#3DABFE"/>
          <stop offset="1" stop-color="#4F83F9"/>
        </linearGradient>
        <linearGradient id="paint4_linear" x1="14" y1="0" x2="14" y2="28" gradientUnits="userSpaceOnUse">
          <stop stop-color="#3DABFE"/>
          <stop offset="1" stop-color="#4F83F9"/>
        </linearGradient>
        <linearGradient id="paint5_linear" x1="14" y1="0" x2="14" y2="28" gradientUnits="userSpaceOnUse">
          <stop stop-color="#3DABFE"/>
          <stop offset="1" stop-color="#4F83F9"/>
        </linearGradient>
      </defs>
    </svg>
    <svg v-if="color === 'white'"
         width="28"
         height="28"
         viewBox="0 0 28 28"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M14 0C11.2859 0 9.07812 2.26248 9.07812 4.97656C9.07812 7.69065 11.2859 9.89844 14 9.89844C16.7141 9.89844 18.9219 7.69065 18.9219 4.97656C18.9219 2.26248 16.7141 0 14 0Z"
            fill="white"/>
      <path d="M15.6406 9.89844H14H12.3594C11.2023 9.89844 10.0842 10.1975 9.06774 10.7063C10.7942 11.1722 12.4197 11.8431 14 12.7501C15.5804 11.8431 17.2058 11.1722 18.9323 10.7063C17.9158 10.1975 16.7977 9.89844 15.6406 9.89844Z"
            fill="white"/>
      <path d="M26.3594 14.8203C25.4533 14.8203 24.7188 15.5548 24.7188 16.4609V19.7422C24.7188 20.6482 25.4533 21.3828 26.3594 21.3828C27.2655 21.3828 28 20.6482 28 19.7422V16.4609C28 15.5548 27.2655 14.8203 26.3594 14.8203Z"
            fill="white"/>
      <path d="M1.64062 14.8203C0.734508 14.8203 0 15.5548 0 16.4609V19.7422C0 20.6482 0.734508 21.3828 1.64062 21.3828C2.54674 21.3828 3.28125 20.6482 3.28125 19.7422V16.4609C3.28125 15.5548 2.54674 14.8203 1.64062 14.8203Z"
            fill="white"/>
      <path d="M2.46094 11.5391C2.00752 11.5391 1.64062 11.906 1.64062 12.3594V13.1797C3.45029 13.1797 4.92188 14.6513 4.92188 16.4609V19.7422C4.92188 21.5519 3.45029 23.0234 1.64062 23.0234V23.8438C1.64062 24.2972 2.00752 24.6641 2.46094 24.6641C6.66646 24.6641 10.0291 25.9922 13.1797 28V14.1965C10.0149 12.382 6.61188 11.5391 2.46094 11.5391Z"
            fill="white"/>
      <path d="M26.3594 13.1797V12.3594C26.3594 11.906 25.9925 11.5391 25.5391 11.5391C21.3881 11.5391 17.9851 12.382 14.8203 14.1965V28C17.9709 25.9922 21.3335 24.6641 25.5391 24.6641C25.9925 24.6641 26.3594 24.2972 26.3594 23.8438V23.0234C24.5497 23.0234 23.0781 21.5519 23.0781 19.7422V16.4609C23.0781 14.6513 24.5497 13.1797 26.3594 13.1797Z"
            fill="white"/>
    </svg>

  </div>
</template>

<script>
    export default {
        name: 'StudentIcon',
        props: ['color']
    }
</script>